import {StockAPI} from "@/api/StockAPI";

/**
 * Requires:
 * - this.formRender[...targetProperties]
 */
const FormAutocompleteMixin = {
    methods: {
        fetchSubStocks: function (stockId = this.form.stockId, targetProperties = ['subStockId'], includeAll = false) {
            if (stockId) {
                targetProperties.forEach(property => this.setFieldItemsLoading(property, true));
                StockAPI.getAllSubstocksForStockAllPages(stockId)
                    .then(response => {
                        this.form.subordinate_stock_id = null;
                        const items = response.data.items.map(substock => ({
                            text: substock.name,
                            value: substock.id,
                            ownerId: substock.owner.id
                        }));
                        if (includeAll && items.length > 1) {
                            items.unshift({
                                text: this.$t('stocks.stockStatus.allSubstocks'),
                                value: null
                            });
                        }
                        targetProperties.forEach(property => {
                            this.$set(this.formRender[property].autocomplete, 'items', items);
                        });
                    }).catch(this.snack)
                    .finally(() => {
                        targetProperties.forEach(property => this.setFieldItemsLoading(property, false));
                    });
            } else {
                targetProperties.forEach(property => {
                    this.$set(this.form, property, null);
                    this.$set(this.formRender[property].autocomplete, 'items', []);
                });
            }
        },
        setFieldItemsLoading: function (property, isLoading) {
            this.$set(this.formRender[property], 'loading', isLoading);
        }
    }
};

export {FormAutocompleteMixin};
