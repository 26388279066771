import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VCard,{staticClass:"ma-1"},[_c(VCardTitle,[_vm._v(" "+_vm._s(_vm.$t(_vm.titlePath))+" ")]),_c(VCardText,[_c(VForm,{staticClass:"d-flex flex-row align-top"},[_c(VAutocomplete,{attrs:{"dense":"","outlined":"","loading":_vm.loading,"readonly":_vm.loading,"items":_vm.possibleProcessingModes,"prepend-icon":"$taskExternalOrderProcessingMode","persistent-hint":"","hint":_vm.taskInfo.details.changeable_mode ?
            _vm.$t('tasks.externalOrder.processingMode.hint.' + _vm.processingMode) :
            _vm.$t('tasks.externalOrder.processingMode.disabledHint'),"disabled":!_vm.taskInfo.details.changeable_mode},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.confirmProcessingMode.apply(null, arguments)}},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c(VLayout,[_c(VFlex,[_vm._v(" "+_vm._s(item.text)+" ")]),_c(VFlex,{staticClass:"text-right"},[_c(VTooltip,{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({},on),[_c(VIcon,[_vm._v("$info")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('tasks.externalOrder.processingMode.hint.' + item.value)))])])],1)],1)]}}]),model:{value:(_vm.processingMode),callback:function ($$v) {_vm.processingMode=$$v},expression:"processingMode"}}),_c(VBtn,{staticClass:"ml-2",attrs:{"outlined":_vm.initialProcessingMode === _vm.processingMode,"disabled":_vm.processingMode === null || _vm.initialProcessingMode === _vm.processingMode,"loading":_vm.loading,"type":"submit","color":"accent"},on:{"click":function($event){$event.preventDefault();return _vm.confirmProcessingMode.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('base.save'))+" ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }