<template>
  <v-autocomplete
    v-bind="$attrs"
    ref="autocomplete"
    :value="value"
    :clearable="clearable"
    :items="items"
    :loading="loading"
    :multiple="multiple"
    :search-input.sync="search"
    :small-chips="chips"
    :prepend-icon="prependIcon"
    @input="onInput"
    @update:search-input="onUpdateSearch"
    @click:clear="$emit('click:clear')"
  >
    <template
      v-if="loading || morePagesAvailable"
      #no-data
    >
      <span v-if="loading">
        <!-- intentionally blank while loading -->
      </span>
      <div
        v-if="morePagesAvailable"
        v-intersect="checkIntersect"
      />
    </template>
    <template
      v-if="$scopedSlots['append-each-item'] !== undefined"
      #item="{item}"
    >
      <slot
        name="append-each-item"
        :item="item"
      />
    </template>
    <template #prepend-item>
      <template v-if="prependSearchBar">
        <v-container fluid>
          <v-row no-gutters>
            <v-col>
              <slot
                name="prepend-item"
              />
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <v-text-field
                v-if="prependSearchBar"
                :value="search"
                :label="$attrs.label"
                prepend-icon="$search"
                clearable
                @input="onUpdateSearch"
              />
            </v-col>
          </v-row>
        </v-container>
      </template>
      <slot
        v-else
        name="prepend-item"
      />
    </template>
    <template #append-item>
      <v-skeleton-loader
        v-if="loading"
        type="card-heading"
      />
      <div v-intersect="checkIntersect" />
    </template>
    <slot
      v-for="name in slotNames"
      :slot="name"
      :name="name"
    />
    <template
      v-for="name in scopedSlotNames"
      #[name]="slotProps"
    >
      <slot
        :name="name"
        v-bind="slotProps"
      />
    </template>
  </v-autocomplete>
</template>

<script>
    import {APIFilterOP, APIFilters} from "@/service/APIFilters";
    import {debounce} from "lodash";

    const PAGE_SIZE = 20;

    /**
     * v-autocomplete wrapper with paginated api-sourced items and fulltext search
     */
    export default {
        name: "XAutocomplete",
        props: {
            value: {
                type: [Number, String, Array],
                default: null
            },
            apiSource: {
                type: Object,
                default: () => ({})
            },
            disableAutoselectFirst: {
                type: Boolean,
                default: false
            },
            multiple: {
                type: Boolean,
                default: false
            },
            clearable: {
                type: Boolean,
                default: true,
            },
            chips: {
                type: Boolean,
                default: false,
            },
            allItemLabel: {
                type: String,
                default: null
            },
            prependIcon: {
                type: String,
                default: null
            },
            prependSearchBar: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            page: 1,
            item_count: PAGE_SIZE + 1,
            loading: false,
            items: [],
            disableFullText: 0,
            search: null,
            fulltext: null,
        }),
        computed: {
            apiDataSource: function () {
                return this.apiSource.apiDataSource ?? (() => Promise.resolve({data: []}));
            },
            apiFilter: function () {
                return this.apiSource.apiFilter ?? [];
            },
            apiFulltextFilter: function () {
                return this.apiSource.apiFulltextFilter ?? ((fulltext) => ({
                    [APIFilterOP.FULL_TEXT]: `"${fulltext}"`
                }));
            },
            apiSort: function () {
                return this.apiSource.apiSort ?? [];
            },
            apiPrimaryKeyName: function () {
                return this.apiSource.apiPrimaryKeyName ?? 'id';
            },
            resultTransform: function () {
                return this.apiSource.resultTransform ?? (a => a);
            },
            debouncedRefreshSearch: function () {
                return debounce(() => {
                    if (this.searchValid) {
                        if (this.fulltext === this.trimmedSearch) {
                            // fulltext watcher will not be triggered, no refresh needed, disable loading
                            this.loading = false;
                        }
                        this.fulltext = this.trimmedSearch;
                    } else {
                        this.reset();
                    }
                }, 500);
            },
            trimmedSearch: function () {
                return typeof this.search === 'string' ? this.search.trim() : '';
            },
            searchValid: function () {
                return !!(this.search && this.trimmedSearch);
            },
            slotNames: function () {
                return Object.keys(this.$slots).filter(name => name !== 'prepend-item');
            },
            scopedSlotNames: function () {
                return Object.keys(this.$scopedSlots).filter(name => name !== 'prepend-item');
            },
            morePagesAvailable: function () {
                return this.item_count > this.page * PAGE_SIZE - PAGE_SIZE;
            }
        },
        createdOrActivated: function () {
            this.$nextTick(() => {
                this.reset(false);
            });
        },
        watch: {
            apiDataSource: function () {
                this.reset();
            },
            apiFilter: function () {
                this.reset();
            },
            apiSort: function () {
                this.reset();
            },
            loading: function (val) {
                this.$emit('update:loading', val);
            },
            fulltext: function () {
                this.reset(false);
            },
            value: {
                immediate: true,
                handler: function (value) {
                    if (this.multiple) {
                        // Check if any of multiple values is missing in the items
                        const missingValues = (value || []).filter(partialValue => {
                            return !this.items.some(item => item.value === partialValue);
                        });
                        if (missingValues.length) {
                            this.fetchPrimaryKey(missingValues);
                        }
                    } else {
                        // Check if selected value is missing in the items
                        if (value && !this.items.some(item => item.value === value)) {
                            this.fetchPrimaryKey(value);
                        }
                    }
                }
            },
        },
        methods: {
            blur: function () {
                if (this.$refs.autocomplete !== undefined) {
                    this.$refs.autocomplete.blur();
                }
            },
            checkIntersect: function (entries, observer, isIntersecting) {
                if (isIntersecting && !this.loading) {
                    this.page++;
                    this.fetchNext();
                }
            },
            reset: function (resetFulltext = true) {
                this.page = 1;
                this.item_count = PAGE_SIZE + 1;
                this.items = [];
                resetFulltext && (this.fulltext = null);
                if (this.multiple ? (this.value && this.value.length) : this.value) {
                    this.fetchPrimaryKey(this.value);
                }
                this.fetchNext();
            },
            getApiFilter: function (primaryKey = null) {
                const filterArray = Array.isArray(this.apiFilter) ? [...this.apiFilter] : [{...this.apiFilter}];
                if (this.fulltext && !primaryKey) {
                    // computed returns function
                    // eslint-disable-next-line vue/no-use-computed-property-like-method
                    filterArray.push(this.apiFulltextFilter(this.fulltext));
                }
                if (primaryKey) {
                    filterArray.push({
                        [this.multiple ? APIFilterOP.IN : APIFilterOP.EQUALS]: {
                            [this.apiPrimaryKeyName]: primaryKey
                        }
                    });
                }
                return filterArray.length ? APIFilters.makeFilter(filterArray) : undefined;
            },
            fetchNext: function () {
                if (this.morePagesAvailable) {
                    this.loading = true;
                    // computed returns function
                    // eslint-disable-next-line vue/no-use-computed-property-like-method
                    this.apiDataSource({
                        page: this.page,
                        itemsPerPage: PAGE_SIZE,
                        filter: this.getApiFilter(),
                        sort: APIFilters.makeSort(this.apiSort)
                    })
                        .then(response => {
                            this.item_count = response.data.item_count || response.data.length;
                            this.addItems(response.data.items || response.data);
                            if (this.item_count === 1 && !this.disableAutoselectFirst) {
                                if (this.multiple) {
                                    this.onInput([this.items[0].value]);
                                } else {
                                    this.onInput(this.items[0].value);
                                }
                            }
                        })
                        .catch(this.snack)
                        .finally(() => {
                            this.loading = false;
                        });
                }
            },
            fetchPrimaryKey: function (primaryKey) {
                let pkItemCount = this.multiple ? primaryKey.length : 1;
                let page = 1;
                while (page <= Math.ceil(pkItemCount / 100 /* max page size */)) {
                    this.loading = true;
                    // computed returns function
                    // eslint-disable-next-line vue/no-use-computed-property-like-method
                    this.apiDataSource({
                        page: page++,
                        itemsPerPage: Math.min(pkItemCount, 100),
                        filter: this.getApiFilter(primaryKey)
                    })
                        .then(response => {
                            this.addItems(response.data.items || response.data);
                        })
                        .catch(this.snack)
                        .finally(() => {
                            this.loading = false;
                        });
                }
            },
            addItems: function (items) {
                this.disableFullText++;
                items.map(this.resultTransform).forEach(item => {
                    this.items.push(item);
                });
                if (this.items.length && this.allItemLabel) {
                    this.items.unshift({
                        text: this.allItemLabel,
                        value: null
                    });
                }
                this.$nextTick(() => { // Wait until input event is processed
                    this.$nextTick(() => { // Wait until update-search event is processed
                        this.disableFullText--;
                    });
                });
            },
            onInput: function (value) {
                let item = undefined;
                // If user chose something, we do not want to start another API call
                if (value) {
                    this.disableFullText++;
                    item = this.items.find(item => item.value === value);
                }
                this.$emit('input', value, item);
                if (value) {
                    this.$nextTick(() => { // Wait until input event is processed
                        this.$nextTick(() => { // Wait until update-search event is processed
                            this.disableFullText--;
                        });
                    });
                }
            },
            onUpdateSearch: function (search) {
                if (this.disableFullText > 0) {
                    return;
                }
                this.search = search;
                this.loading = this.searchValid;
                this.items = [];
                this.debouncedRefreshSearch();
            },
        }
    };
</script>

<style scoped>

</style>
