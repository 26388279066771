<template>
  <div>
    <v-card
      class="ma-1"
    >
      <v-container fluid>
        <v-icon class="mb-1">
          {{ actionIcons.approve }}
        </v-icon>
        <span class="text-subtitle-1 ml-2">
          {{ $t('tasks.deliveryAccept.approve.name') }}
        </span>
        <v-divider
          class="my-2"
        />
        <v-layout wrap>
          <v-form
            ref="form"
            v-model="valid"
            @submit.prevent
          >
            <v-flex
              xs12
            >
              <v-subheader>
                {{ $t('tasks.deliveryAccept.updateDetails') }}
              </v-subheader>
              <v-layout wrap>
                <v-flex
                  xs12
                >
                  <FormFields
                    ref="fields"
                    :form="form"
                    :render="formRender"
                    lang-path="tasks."
                  />
                </v-flex>
                <v-flex
                  xs12
                >
                  <TaskChooseItems
                    :chosen-items.sync="chosenItems"
                    :valid.sync="validItems"
                    title-path="tasks.deliveryAccept.items.delivered"
                    :readonly="loading"
                    require-product-specification
                    allow-create-new-instances
                    allow-empty
                  >
                    <template #instanceItem="{ item }">
                      <DeliveryAcceptItemSelector
                        :delivery-type="form.delivery_type"
                        :item-type="item.type"
                        :item-text="item.text"
                      />
                    </template>
                  </TaskChooseItems>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex
              xs12
            >
              <v-divider />
            </v-flex>
            <v-flex
              xs12
            >
              <v-subheader>
                {{ $t('tasks.deliveryAccept.newTask') }}
              </v-subheader>
              <FormFields
                :form="form"
                :render="formNewTaskRender"
                lang-path="tasks."
              >
                <template #stock_loading_strict_mode-item="{ item }">
                  <v-layout>
                    <v-flex>
                      {{ item.text }}
                    </v-flex>
                    <v-flex class="text-right">
                      <v-tooltip right>
                        <template #activator="{ on }">
                          <div v-on="on">
                            <v-icon>$info</v-icon>
                          </div>
                        </template>
                        <span>{{ $t('tasks.stockLoading.strictHint.' + item.value) }}</span>
                      </v-tooltip>
                    </v-flex>
                  </v-layout>
                </template>
              </FormFields>
            </v-flex>
            <v-flex
              v-if="form.stock_loading_subordinate_stock_id"
              xs12
              class="mb-3"
            >
              <v-divider
                class="my-3"
              />
              <TaskAttributes
                :task-type="taskTypes.STOCK_LOADING"
                :stock-id="form.stock_loading_stock_id"
                :sub-stock-id="form.stock_loading_subordinate_stock_id"
                :submit-trigger="attributesSubmitTrigger"
                inline
                @update:valid="val => validAttributes = val"
                @update-submit-callback="callback => attributesSubmitCallback = callback"
              />
            </v-flex>
          </v-form>
        </v-layout>
        <div>
          <v-btn
            :text="!validAll"
            :loading="loading"
            type="submit"
            color="accent"
            @click="approve()"
          >
            <v-icon
              class="mr-2"
            >
              $approve
            </v-icon>
            {{ $t('tasks.deliveryAccept.approve.name') }}
          </v-btn>
        </div>
      </v-container>
    </v-card>
    <div class="my-2" />
    <SupplierCreateUpdate
      v-if="showCreateSupplier"
      id="supplierForm"
      ref="supplierForm"
      class="ma-1"
      :is-edit="false"
      is-component
    />
    <ProductCustomInstanceCreateUpdate
      v-if="creatingInstanceForProductId !== null"
      id="instanceForm"
      ref="instanceForm"
      class="ma-1"
      :product-id-prop="creatingInstanceForProductId"
      :is-edit="false"
      is-component
    />
  </div>
</template>

<script>
    import FormFields from "@/app/components/form/FormFields.component";
    import {
        DeliveryAcceptApproveForm, DeliveryAcceptApproveNewTaskRender,
        DeliveryAcceptApproveRender
    } from "@/app/tasks/deliveryAccept/definitions/deliveryAcceptApprove.form";
    import {taskNames, taskTypes} from "@/enum/task_type";
    import {TaskDeliveryAcceptAPI as API} from "@/api/TaskDeliveryAcceptAPI";
    import {populate} from "@/utils/object";
    import {clearFormErrors, setFormErrors} from "@/utils/form";
    import {actionIcons} from "@/enum/icons";
    import {StockAPI} from "@/api/StockAPI";
    import SupplierCreateUpdate from "@/app/suppliers/SupplierCreateUpdate.view";
    import {scrollTo} from "@/service/Vuetify";
    import TaskChooseItems from "@/app/tasks/components/TaskChooseItems.component";
    import ProductCustomInstanceCreateUpdate
        from "@/app/products/custom-instances/ProductCustomInstanceCreateUpdate.view";
    import {deliveryTypes} from "@/enum/delivery_type";
    import {InstanceType} from "@/enum/instance_type";
    import {EventsListenerMixin} from "@/app/mixins/EventsListenerMixin";
    import DeliveryAcceptItemSelector
        from "@/app/tasks/deliveryAccept/components/DeliveryAcceptItemSelector.component.vue";
    import TaskAttributes from "@/app/tasks/components/taskAttributes/TaskAttributes.component.vue";
    import {getIdFromLocation} from "@/utils/url";

    export default {
        name: "DeliveryAcceptApproveCard",
        components: {
            TaskAttributes,
            DeliveryAcceptItemSelector,
            FormFields, SupplierCreateUpdate, TaskChooseItems, ProductCustomInstanceCreateUpdate
        },
        mixins: [EventsListenerMixin],
        props: {
            taskId: {
                type: Number,
                default: undefined
            },
            defaultData: {
                type: Object,
                default: () => ({})
            }
        },
        data: () => ({
            form: new DeliveryAcceptApproveForm,
            formNewTaskRender: new DeliveryAcceptApproveNewTaskRender,
            formRender: new DeliveryAcceptApproveRender,
            valid: true,
            validItems: true,
            validAttributes: true,
            loading: false,
            actionIcons: actionIcons,
            showCreateSupplier: false,
            creatingInstanceForProductId: null,
            chosenItems: [{product_id: null, product_instance_id: null, quantity: 1}],
            deliveryTypes: deliveryTypes,
            InstanceType: InstanceType,
            taskTypes: taskTypes,
            attributesSubmitTrigger: 0,
            attributesSubmitCallback: null,
        }),
        computed: {
            events: function () {
                return {
                    'create-supplier': this.onCreateSupplier,
                    'create-supplier-created': this.onCreateSupplierCreated,
                    'create-supplier-cancelled': () => this.showCreateSupplier = false,
                    'create-instance': this.onCreateInstance,
                    'create-instance-created': () => this.creatingInstanceForProductId = null,
                    'create-instance-cancelled': () => this.creatingInstanceForProductId = null
                };
            },
            validAll: function () {
                return this.valid && this.validItems && this.validAttributes;
            }
        },
        watch: {
            'form.stock_loading_stock_id': function (newValue) {
                if (newValue !== undefined && newValue !== null) {
                    this.loadSubStocks();
                }
            },
        },
        createdOrActivated: function () {
            populate(this.form, this.defaultData);
            this.fetchDeliveredItems();
        },
        mounted: function () {
            clearFormErrors(this);
        },
        methods: {
            fetchDeliveredItems: function () {
                this.loading = true;
                API.getAllItems(this.taskId).then(response => {
                    this.chosenItems = response.data;
                }).catch(this.snack)
                    .finally(() => this.loading = false);
            },
            loadSubStocks: function () {
                this.$set(this.formNewTaskRender.stock_loading_subordinate_stock_id, 'loading', true);
                StockAPI.getAllSubstocksForStockAllPages(this.form.stock_loading_stock_id)
                    .then(response => {
                        this.formNewTaskRender.stock_loading_subordinate_stock_id.autocomplete.items = response.data.items.map(el => ({
                            text: el.name,
                            value: el.id
                        }));
                    }).finally(() => {
                        this.$set(this.formNewTaskRender.stock_loading_subordinate_stock_id, 'loading', false);
                    });
            },
            onCreateSupplier: function () {
                this.showCreateSupplier = true;
                if (this.$refs.fields !== undefined) {
                    this.$refs.fields.$refs.supplier_id[0].blur();
                }
                this.$nextTick(() => {
                    scrollTo('supplierForm');
                });
            },
            onCreateSupplierCreated: function (supplierId) {
                this.showCreateSupplier = false;
                this.$set(this.formRender.supplier_id, 'loading', true);
                this.formRender.supplier_id.autocomplete.callFn()
                    .then(response => {
                        this.formRender.supplier_id.autocomplete.items = this.formRender.supplier_id.autocomplete.thenFn(response);
                        this.form.supplier_id = Number.parseInt(supplierId, 10);
                    }).finally(() => this.$set(this.formRender.supplier_id, 'loading', false));
            },
            onCreateInstance: function (productId) {
                this.creatingInstanceForProductId = productId;
                this.$nextTick(() => {
                    scrollTo('instanceForm');
                });
            },
            approve: function () {
                if (!this.validAll) {
                    this.$refs.form.validate();
                    return;
                }
                this.loading = true;
                this.attributesSubmitTrigger++;
                this.form.hours = this.$store.getters['time/currentHours'](Date.now());
                const data = {...this.form};
                data.stock_loading_items = this.chosenItems
                    .filter(item => {
                        delete item.errors;
                        return item.product_instance_id;
                    });
                API.approve(this.taskId, data)
                    .then(response => {
                        this.$store.dispatch('time/stop');
                        const stockLoadingId = getIdFromLocation(response);
                        this.attributesSubmitCallback(stockLoadingId)
                            .then(() => {
                                this.advancedSnack({
                                    text: 'tasks.approve.done',
                                    params: [
                                        this.taskId,
                                        this.$t(taskNames[taskTypes.DELIVERY_ACCEPT])
                                    ]
                                });
                                this.$router.push('/');
                            }).catch(this.snack)
                            .finally(() => this.loading = false);
                    }).catch(errors => {
                        setFormErrors.call(this, errors, ['formRender', 'formNewTaskRender']);
                        this.loading = false;
                    });
            }
        }
    };
</script>

<style scoped>

</style>
