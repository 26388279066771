<template>
  <div>
    <ConfirmDeleteDialog
      v-if="deletedSubStock !== null"
      :show.sync="confirmDialog"
      :text="deletedSubStock.name + ', ' + deletedSubStock.owner.name"
      @confirm-delete="reallyDeleteSubStock"
    />
    <v-expansion-panels
      :value="0"
    >
      <v-expansion-panel>
        <v-expansion-panel-header class="text-subtitle-1">
          {{ $t('stocks.substocks.label') }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <x-data-table
            :headers="headers"
            :loading.sync="loading"
            :items.sync="items"
            :actions="actions"
            :api-data-source="apiDataSource"
            :api-data-source-all-pages="apiDataSourceAllPages"
            :reload="reload"
          >
            <template #footer>
              <TableAddItemButton
                :to="substockCreateLink"
                label="stocks.substocks.create.titleSimple"
              />
            </template>
          </x-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
    import {StockAPI} from "@/api/StockAPI";
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";
    import {APIFilters} from "@/service/APIFilters";
    import {createHeaders} from "@/utils/table";
    import {substockTable} from "@/app/stocks/substocks/definitions/substock.form";
    import TableAddItemButton from "@/app/components/table/TableAddItemButton.component";
    import ConfirmDeleteDialog from "@/app/components/ConfirmDeleteDialog.component";

    export default {
        name: "StockSubstocksList",
        components: {TableAddItemButton, ConfirmDeleteDialog},
        mixins: [RouteParamsMapperMixin],
        data: () => ({
            loading: true,
            headers: [],
            items: [],
            reload: 0,
            deletedSubStock: null,
            confirmDialog: false
        }),
        computed: {
            substockCreateLink: function () {
                return '/stocks/' + this.stockId + '/substocks/create';
            },
            actions: function () {
                return [
                    {
                        loading: this.loading,
                        routerLink: item => '/stocks/' + this.stockId + '/substocks/' + item.id,
                        icon: '$showItem',
                        label: 'base.view'
                    }, {
                        loading: this.loading,
                        routerLink: item => '/stocks/' + this.stockId + '/substocks/' + item.id + '/update',
                        icon: '$updateItem',
                        label: 'base.edit'
                    }, {
                        loading: this.loading,
                        action: this.deleteItem,
                        icon: '$deleteTableItem',
                        label: 'base.delete'
                    }
                ];
            },
            apiDataSource: function () {
                return StockAPI.getAllSubstocksForStock.bind(StockAPI, this.stockId, {sort: APIFilters.makeSort({created_at: 'DESC'})});
            },
            apiDataSourceAllPages: function () {
                return StockAPI.getAllSubstocksForStockAllPages.bind(StockAPI, this.stockId);
            }
        },
        createdOrActivated: function () {
            this.headers = createHeaders(substockTable, 'stocks.substocks.');
        },
        methods: {
            deleteItem: function (item) {
                this.deletedSubStock = item;
                this.confirmDialog = true;
            },
            reallyDeleteSubStock: function () {
                this.loading = true;
                this.confirmDialog = false;
                StockAPI.deleteSubstock(this.stockId, this.deletedSubStock.id)
                    .then(() => {
                        this.advancedSnack({
                            text: 'stocks.substocks.delete.done',
                            params: [this.deletedSubStock.name]
                        });
                    }).catch(this.snack)
                    .finally(() => {
                        this.loading = false;
                        this.reload++;
                    });
            }
        }
    };
</script>

<style scoped>

</style>
