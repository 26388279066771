<template>
  <div :class="'TaskItemsLocations-' + cardType">
    <transition-group
      name="card-list"
      mode="out-in"
      class="d-flex flex-column ga-0"
    >
      <TaskItemsLocation
        v-for="(locationId, order) of locationsTrimmed"
        :key="locationId"
        :active="activeCardIsOnThisSide && locationId === activeLocationId"
        :location-id="locationId"
        :force-update="forceUpdate"
        :items="items.filter(item => itemIsOnLocation(item, locationId))"
        :style="{ order: order }"
      >
        <template #items-location-header>
          <slot
            name="items-location-header"
            :location-id="locationId"
          />
        </template>
        <template #card-item="{ item }">
          <slot
            name="card-item"
            :item="item"
            :location-id="locationId"
          />
        </template>
      </TaskItemsLocation>
      <v-card
        v-show="showNothingCard"
        key="empty"
        outlined
        class="card-list-item mx-3 mt-3 pa-2 pt-3 text--disabled overflow-hidden"
      >
        <v-icon class="mb-1 mx-2">
          $nothing
        </v-icon>
        {{ $t('base.nothing') }}
      </v-card>
    </transition-group>
    <v-btn
      v-show="hasTrimmedLocations"
      key="showMoreButton"
      block
      text
      color="secondary"
      @click="showMoreLocations"
    >
      {{ $t('base.list.loadMore') + ` (${locations.length - locationLimit})` }}
    </v-btn>
  </div>
</template>

<script>
    import TaskItemsLocation from "@/app/tasks/components/taskItemsCard/TaskItemsLocation.component.vue";
    import {TaskItemsCardType} from "@/enum/task_items_card_type";
    import {activeCardColor} from "@/styles/theme";
    import {EventsListenerMixin} from "@/app/mixins/EventsListenerMixin";
    import {ReactiveLocationCacheMixin} from "@/app/mixins/ReactiveLocationCacheMixin";
    import {debounce} from "lodash";
    import {hasSameUniqueUnorderedItems} from "@/utils/array";
    import {Sentry} from "@/service/Sentry";
    import {TaskTypeMixin} from "@/app/mixins/TaskTypeMixin";
    import {taskTypes} from "@/enum/task_type";
    import {TaskShippingType} from "@/enum/task_shipping_type";

    export default {
        name: "TaskItemsLocations",
        components: {TaskItemsLocation},
        // TaskTypeMixin used for debug purposes only
        mixins: [EventsListenerMixin, ReactiveLocationCacheMixin, TaskTypeMixin],
        props: {
            activeLocationId: {
                type: Number,
                default: null
            },
            activeCardIsOnThisSide: {
                type: Boolean,
                default: false
            },
            cardType: {
                type: Number,
                default: 0
            },
            items: {
                type: Array,
                default: () => []
            },
            itemIsOnLocation: {
                type: Function,
                default: () => []
            },
            locationIdWhitelist: {
                type: Array,
                default: () => []
            },
            locationIdBlacklist: {
                type: Array,
                default: () => []
            },
            sortTaskItemsByLoadedSettings: {
                type: Boolean,
                default: false
            },
            taskInfo: {
                type: Object,
                default: () => ({})
            },
            debugItemQuantity: {
                type: Number,
                default: 0
            },
            // for debug purposes only
            itemQuantityOnLocation: {
                type: Function,
                default: () => []
            }
        },
        data: () => ({
            activeCardColor: activeCardColor,
            TaskItemsCardType: TaskItemsCardType,
            locations: [],
            locationLimit: 10,
            itemCountOnLocation: {},
            oldestLoadTimeForLocation: {},
            locationsCached: false,
            forceUpdate: 0
        }),
        computed: {
            events: function () {
                return {
                    'taskItems-quantitiesLoaded': this.computeLocations,
                };
            },
            showNothingCard: function () {
                return this.locations.length === 0
                    && [TaskItemsCardType.TO_MOVE, TaskItemsCardType.MOVED].includes(this.cardType);
            },
            locationsTrimmed: function () {
                if (this.cardType === TaskItemsCardType.TO_MOVE) {
                    // TODO use toSorted one Electron is updated on the clients
                    return [...this.locations].sort(this.locationOrderComparator).slice(0, this.locationLimit);
                }
                return this.locations.slice(0, this.locationLimit);
            },
            hasTrimmedLocations: function () {
                return this.locations.length > this.locationLimit;
            },
            activeLocationWatchSource: function () {
                return `${this.activeLocationId}-${this.activeCardIsOnThisSide}-${this.locationIdBlacklist.join('-')}`;
            },
            fixActiveLocationDebounced: function () {
                return debounce(this.fixActiveLocation, 20);
            },
            stockId: function () {
                return (this.taskInfo.details
                    && ((this.taskInfo.details.stock && this.taskInfo.details.stock.id)
                        || (this.taskInfo.details.subordinate_stock && this.taskInfo.details.subordinate_stock.stock_id)
                        || (this.taskInfo.details.source_subordinate_stock && this.taskInfo.details.source_subordinate_stock.stock_id))
                );
            },
        },
        createdOrActivated: function () {
            this.locationsCached = false;
            if (this.$vuetify.breakpoint.mdAndUp) {
                this.locationLimit = 20;
            }
            this.computeLocations();

            this.$store.watch(state => state.userConfig.pickFromMobileLocation, (value) => {
                if (value) {
                    this.computeLocations();
                } else {
                    this.filterSpecialLocations();
                }
            });
        },
        watch: {
            sortTaskItemsByLoadedSettings: {
                immediate: true,
                handler: function (value) {
                    if (value) {
                        this.computeOldestLocations();
                    }
                }
            },
            locationIdWhitelist: function (newVal, oldVal) {
                if (!hasSameUniqueUnorderedItems(newVal, oldVal)) {
                    // To only trigger the change when dataset changes, regardless of order
                    this.computeLocations();
                }
            },
            locationIdBlacklist: function (newVal, oldVal) {
                if (!hasSameUniqueUnorderedItems(newVal, oldVal)) {
                    // To only trigger the change when dataset changes, regardless of order
                    this.computeLocations();
                }
            },
            activeLocationWatchSource: function () {
                this.fixActiveLocationDebounced();
            },
            debugItemQuantity: function () {
                this.handleDebugItemQuantity();
            },
        },
        methods: {
            showMoreLocations: function () {
                this.locationLimit += this.$vuetify.breakpoint.smAndDown ? 10 : 20;
            },
            computeItemCountOnLocation: function () {
                this.itemCountOnLocation = {};
                for (const item of this.items) {
                    for (const itemLocation of (item.locations || item.destination_locations)) {
                        const locationId = itemLocation.stock_location?.id || itemLocation.location_id;
                        this.itemCountOnLocation[locationId] = this.itemCountOnLocation[locationId] + 1 || 1;
                    }
                }
            },
            computeOldestLocations: function () {
                this.oldestLoadTimeForLocation = {};
                for (const item of this.items) {
                    for (const itemLocation of item.locations) {
                        itemLocation.loaded_at = itemLocation.loaded_at || '0'; // Get rid of nulls, count them as oldest
                        const currentOldest = this.oldestLoadTimeForLocation[itemLocation.stock_location.id];
                        if (currentOldest === undefined || (currentOldest > itemLocation.loaded_at)) {
                            this.oldestLoadTimeForLocation[itemLocation.stock_location.id] = itemLocation.loaded_at;
                        }
                    }
                }
            },
            computeLocations: function () {
                if (this.cardType === TaskItemsCardType.TO_MOVE) {
                    this.computeItemCountOnLocation();
                    if (this.sortTaskItemsByLoadedSettings) {
                        this.computeOldestLocations();
                    }
                }

                const allNonUniqueLocations = this.items
                    .flatMap(item => (item.locations || item.destination_locations || [item.stock_location_id])
                        .map(loc => loc.location_id || loc.stock_location?.id || loc)
                    );
                const allLocations = [...new Set(allNonUniqueLocations)];
                const noBlacklistedLocations = allLocations.filter(id => !this.locationIdBlacklist.includes(id));
                let newLocations;
                if (this.locationIdWhitelist && this.locationIdWhitelist.length) {
                    newLocations = noBlacklistedLocations.filter(id => this.locationIdWhitelist.includes(id));
                } else {
                    newLocations = noBlacklistedLocations;
                }

                if (newLocations.length === 0 && this.locationIdWhitelist?.length > 0) {
                    newLocations = this.locationIdWhitelist;
                }

                if (!this.locationsCached && !hasSameUniqueUnorderedItems(newLocations, this.locations)) {
                    // Locations are not cached yet, set them right away otherwise user would have to wait too long for them to display
                    // If locations are already cached filter them first otherwise wrong locations might be displayed for very short period of time
                    // Do not set locations if they are the same as current locations
                    this.locations = newLocations;
                }

                this.fetchLocationDetails(newLocations);
            },
            fixActiveLocation: function () {
                if (this.activeLocationId
                    && this.activeCardIsOnThisSide
                    && !this.locationIdBlacklist.includes(this.activeLocationId)
                ) {
                    const activeLocationPosition = this.locations.indexOf(this.activeLocationId);
                    switch (activeLocationPosition) {
                    case -1: // missing
                        this.locations.unshift(this.activeLocationId);
                        break;
                    case 0: // already first
                        break;
                    default: // not first
                        this.locations.splice(activeLocationPosition, 1);
                        this.locations.unshift(this.activeLocationId);
                    }
                }
            },
            fetchLocationDetails: function (computedLocations) {
                const promises = [];
                if (this.stockId) {
                    promises.push(this.cacheLocationMany(this.stockId, computedLocations));
                } else {
                    for (const locationId of this.locations) {
                        promises.push(this.cacheLocation(locationId));
                    }
                }
                Promise.all(promises)
                    .then(() => {
                        this.locationsCached = true;
                        this.filterSpecialLocations(computedLocations);
                    }).catch(this.snack);
            },
            filterSpecialLocations: function (computedLocations = null) {
                if (this.cardType === TaskItemsCardType.TO_MOVE) {
                    computedLocations ??= this.locations;
                    const noMobileLocations = computedLocations.filter(id => this.specialLocationFilter(id, false));
                    if (noMobileLocations.length > 0) {
                        if (!hasSameUniqueUnorderedItems(noMobileLocations, this.locations)) {
                            // there are some locations which are not mobile and they differ from current locations
                            this.locations = noMobileLocations;
                            // trigger debounced fix of active location
                            // if we haven't fixed active location yet, then this call won't make any difference (fixActiveLocation is still scheduled to be executed)
                            // if we have fixed active location already, then we have to fix it again for newly set locations
                            this.fixActiveLocationDebounced();
                        }
                    } else {
                        // allow pick even from mobile locations
                        const justMobileSourceLocations = computedLocations.filter(id => this.specialLocationFilter(id, true));
                        if (justMobileSourceLocations.length > 0) {
                            if (!hasSameUniqueUnorderedItems(justMobileSourceLocations, this.locations)) {
                                // there are some locations which are just mobile (not expedition) and they differ from current locations
                                this.locations = justMobileSourceLocations;
                                this.fixActiveLocationDebounced();
                            }
                        } else if (!hasSameUniqueUnorderedItems(computedLocations, this.locations)) {
                            // there are no locations which are not expedition and new computed locations differ from current locations
                            this.locations = computedLocations;
                            this.fixActiveLocationDebounced();
                        }
                    }
                }
            },
            specialLocationFilter: function (locationId, allowMobile = false) {
                const pickFromMobileLocation = this.$store.getters['userConfig/pickFromMobileLocation'];
                if (this.locationsCached) {
                    if (this.LocationCache[locationId] !== undefined && typeof this.LocationCache[locationId] !== 'string') {
                        return !this.LocationCache[locationId].is_expedition && (
                            (allowMobile || pickFromMobileLocation) ? true : !this.LocationCache[locationId].is_mobile);
                    } else {
                        return false;
                    }
                } else {
                    return true;
                }
            },
            locationOrderComparator: function (a, b) {
                // if active location exists in array move it to front
                const activeFirst = a === this.activeLocationId ? -1 : b === this.activeLocationId ? 1 : 0;
                const byOldestLoadedAt = 0.5 - (this.oldestLoadTimeForLocation[a] < this.oldestLoadTimeForLocation[b]);
                const byOldestLoadedAtIfEnabled = this.sortTaskItemsByLoadedSettings ? byOldestLoadedAt : 0;
                const byItemCountOnLocation = this.itemCountOnLocation[b] - this.itemCountOnLocation[a];
                const byId = b - a;

                if (this.locationsCached) {
                    const byAvailability = (this.LocationCache[b]?.availability || 50) - (this.LocationCache[a]?.availability || 50);
                    const byCode = 0.5 - (this.LocationCache[a]?.code < this.LocationCache[b]?.code);
                    return activeFirst || byOldestLoadedAtIfEnabled || byAvailability || byCode;
                } else {
                    return activeFirst || byOldestLoadedAtIfEnabled || byItemCountOnLocation || byId;
                }
            },
            handleDebugItemQuantity: function () {
                const payload = {};
                for (const locationId of this.locationsTrimmed) {
                    const locationInfo = {};
                    for (const item of this.items.filter(item => this.itemIsOnLocation(item, locationId))) {
                        const loc = item.locations.find(location => location.stock_location.id === locationId);

                        let processed_quantity = item.processed_quantity;
                        if (this.isAnyOfTypes([taskTypes.STOCK_PICKING, taskTypes.STOCK_PICKING_SET])
                            && this.taskInfo.details.shipping_type === TaskShippingType.PERSONAL_COLLECTION) {
                            // in personal collection quantity in inventory is the same as processed quantity
                            processed_quantity = 0;
                        }

                        const itemQuantityOnLocation = this.itemQuantityOnLocation(item, locationId);
                        const remainingMoveQuantity = item.quantity_to_move - item.quantity_in_user_inventory - processed_quantity;
                        locationInfo[item.id] = {
                            instanceId: item.product_instance_id,
                            itemQuantityOnLocation: itemQuantityOnLocation,
                            foundLocationId: loc?.stock_location.id,
                            locationQuantity: loc?.quantity,
                            itemQuantityToMove: item.quantity_to_move,
                            minOfLocationQuantityAndQuantityToMove: Math.min(loc?.quantity, item.quantity_to_move),
                            processedQuantity: processed_quantity,
                            quantityInUserInventory: item.quantity_in_user_inventory,
                            remainingMoveQuantity: remainingMoveQuantity,
                            displayedQuantity: Math.min(remainingMoveQuantity, itemQuantityOnLocation)
                        };
                    }
                    payload[locationId] = locationInfo;
                }
                console.error('Item quantity DEBUG');
                Sentry.captureMessage('Item quantity DEBUG', undefined, payload);
                this.advancedSnack({ translatedText: 'Item quantity DEBUG data sent.'});
            }
        }
    };
</script>

<style scoped>

</style>
