import {countries} from "@/enum/countries";
import {TableFilter} from "@/enum/table_filter";

function Email() {
    return {
        icon: '$email',
        type: 'email',
        max: 50
    };
}

function Phone() {
    return {
        icon: '$phone',
        max: 20
    };
}

function AddressForm() {
    return {
        street: null,
        house_number: null,
        suburb: null,
        city: null,
        postal_code: null,
        phone: null,
        email: null,
        country: null
    };
}

function AddressRender() {
    return {
        street: {
            icon: '$street',
            max: 50
        },
        house_number: {
            icon: '$houseNumber',
            max: 16
        },
        suburb: {
            icon: '$suburb',
            max: 100
        },
        city: {
            icon: '$city',
            max: 50
            // TODO suggest list of czech municipalities
        },
        postal_code: {
            icon: '$postalCode',
            max: 8
            // TODO fill in city from postal code
        },
        phone: new Phone(),
        email: new Email(),
        country_iso_code: {
            icon: '$country',
            select: countries.map(country => ({
                text: country.name + ' (' + country.code + ')',
                value: country.code
            }))
        },
        country: {
            icon: '$country',
            max: 30
            // TODO suggest country names (localised)
        }
    };
}

const addressTable = {
    street: {
        filterType: TableFilter.TEXT
    },
    house_number: {
        filterType: TableFilter.TEXT
    },
    suburb: {
        filterType: TableFilter.TEXT
    },
    city: {
        filterType: TableFilter.TEXT
    },
    postal_code: {
        filterType: TableFilter.TEXT
    },
    country: {
        filterType: TableFilter.TEXT
    }
};

export {Email, Phone, AddressForm, AddressRender, addressTable};
