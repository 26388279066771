<template>
  <div>
    <v-card>
      <x-data-table
        :headers="headers"
        :items.sync="items"
        :item-class="item => item.id === shipmentId && 'font-weight-bold'"
        :loading.sync="loading"
        :actions="actions"
        :api-data-source="apiDataSource"
        :api-data-source-all-pages="apiDataSourceAllPages"
        :batch-actions="batchActions"
        :item-select-color="itemSelectColor"
        :search-hint="$t('base.shipping.table.searchHint')"
      >
        <template #item.subordinate_stock_id="{ item }">
          {{ SubStockCache[item.subordinate_stock_id] | subStockLabel }}
        </template>
        <template #item.carrier_id="{ item }">
          {{ CarrierCache[item.carrier_id] | carrierLabel }}
        </template>
        <template #item.external_order_id="{ item }">
          <span @click.stop>
            <router-link :to="taskLinks[taskTypes.EXTERNAL_ORDER] + '/' + item.external_order_id">
              #{{ item.external_order_id }}
            </router-link>
          </span>
        </template>
        <template #item.stock_picking_id="{ item }">
          <span @click.stop>
            <router-link :to="taskLinks[taskTypes.STOCK_PICKING] + '/' + item.stock_picking_id">
              #{{ item.stock_picking_id }}
            </router-link>
          </span>
        </template>
        <template #item.buyer.name="{ item }">
          <span @click.stop>
            <router-link :to="'/buyers/' + item.buyer.id">
              {{ item.buyer.name }}
            </router-link>
          </span>
        </template>
        <template #item.last_validated="{ item }">
          <DateTimeWithTooltip
            :date-time="item.last_validated"
          />
        </template>
        <template #item.carrier_api_error_messages="{ item }">
          <v-tooltip
            top
            :disabled="item.carrier_api_error_messages === null"
          >
            <template #activator="{ on }">
              <span
                v-if="item.last_validated !== null && item.carrier_api_error_messages === null"
                v-on="on"
              >
                <v-icon color="success">
                  $success
                </v-icon>
              </span>
              <span
                v-else-if="item.carrier_api_error_messages === null"
                v-on="on"
              >
                <v-icon color="warning">
                  $waiting
                </v-icon>
              </span>
              <span
                v-else
                v-on="on"
              >
                <v-icon color="error">
                  $error
                </v-icon>
              </span>
            </template>
            <div
              v-for="(error, index) in item.carrier_api_error_messages"
              :key="index"
            >
              {{ decodeURIComponent(error) }}
            </div>
          </v-tooltip>
        </template>
        <template #item.external_order_created_at="{ item }">
          <DateTimeWithTooltip
            :date-time="item.external_order_created_at"
            abs-date-first
          />
        </template>
        <template #item.label_first_printed_at="{ item }">
          <DateTimeWithTooltip
            :date-time="item.label_first_printed_at"
            abs-date-first
          />
        </template>
        <template #item.last_protocol_created_at="{ item }">
          <DateTimeWithTooltip
            :date-time="item.last_protocol_created_at"
            abs-date-first
          />
        </template>
        <template #item.sent_date="{ item }">
          <DateTimeWithTooltip
            :date-time="item.sent_date"
            abs-date-first
          />
        </template>
      </x-data-table>
    </v-card>
  </div>
</template>

<script>
    import {ShipmentAPI} from "@/api/ShipmentAPI";
    import {ShipmentCarrierAPI} from "@/api/ShipmentCarrierAPI";
    import DateTimeWithTooltip from "@/app/components/DateTimeWithTooltip.component";
    import {taskLinks, taskTypes} from "@/enum/task_type";
    import {APIFilterOP, APIFilters} from "@/service/APIFilters";
    import * as Export from "@/service/Export";
    import {createHeaders} from "@/utils/table";
    import {StockAPI} from "@/api/StockAPI";
    import {PrintType} from "@/enum/print_type";
    import {isEqual} from "lodash";
    import {shippingTable} from "@/app/overview/shipping/definitions/shipping.form";
    import {ShipmentState} from "@/enum/shipment_state";
    import {EventsListenerMixin} from "@/app/mixins/EventsListenerMixin";
    import {ReactiveSubStockCacheMixin} from "@/app/mixins/ReactiveSubStockCacheMixin";
    import {ReactiveCarrierCacheMixin} from "@/app/mixins/ReactiveCarrierCacheMixin";

    export default {
        name: "ShipmentsList",
        components: {DateTimeWithTooltip},
        mixins: [EventsListenerMixin, ReactiveSubStockCacheMixin, ReactiveCarrierCacheMixin],
        props: {
            shipmentId: {
                type: Number,
                default: null
            }
        },
        data: () => ({
            loading: false,
            items: [],
            carriers: [],
            loadingCarriers: false,
            substocks: [],
            loadingSubstocks: false,
            carrierId: null,
            subStockId: null,
            taskLinks: taskLinks,
            taskTypes: taskTypes
        }),
        computed: {
            events: function () {
                return {
                    'subStock-filtered': this.onSubStockFiltered,
                    'carrier-filtered': this.onCarrierFiltered
                };
            },
            apiDataSource: function () {
                return ShipmentAPI.getAll.bind(ShipmentAPI, {sort: APIFilters.makeSort({created_at: 'DESC'})});
            },
            apiDataSourceAllPages: function () {
                return ShipmentAPI.getAllPages.bind(ShipmentAPI);
            },
            actions: function () {
                return [
                    {
                        action: this.showShipment,
                        icon: '$showItem',
                        label: 'base.shipping.shipment.show'
                    }, {
                        condition: item => !this.hasCarrierErrors(item),
                        action: this.printShipmentLabel,
                        icon: '$printItem',
                        label: 'base.shipping.shipment.printLabel'
                    }, {
                        condition: item => item.tracking_link !== null,
                        action: this.trackShipment,
                        icon: '$track',
                        label: 'base.shipping.shipment.track'
                    }
                ];
            },
            batchActions: function () {
                return [
                    {
                        condition: () => this.carrierId !== null && this.subStockId !== null,
                        hint: 'base.shipping.shipment.printDetail.hint',
                        loading: this.loading,
                        action: this.printShipmentsDetail,
                        icon: '$printItem',
                        label: 'base.shipping.shipment.printDetail.label'
                    }
                ];
            },
            headers: function () {
                return createHeaders(
                    shippingTable(false, this.substocks, this.loadingSubstocks, this.carriers, this.loadingCarriers),
                    'base.shipping.table.'
                );
            }
        },
        watch: {
            items: function () {
                this.items.forEach(item => {
                    const subStockId = item.subordinate_stock_id;
                    if (!this.SubStockCache[subStockId]) {
                        this.cacheSubStock(StockAPI.getSubstockWOStock(subStockId), subStockId);
                    }
                    const carrierId = item.carrier_id;
                    if (!this.CarrierCache[carrierId]) {
                        this.cacheCarrier(ShipmentCarrierAPI.get(carrierId), carrierId);
                    }
                });
            }
        },
        createdOrActivated: function () {
            this.fetchSubstocks();
            this.fetchCarriers();
        },
        methods: {
            fetchSubstocks: function () {
                this.loadingSubstocks = true;
                this.substocks = [];
                StockAPI.getAllPages().then(response => {
                    StockAPI.getAllSubstocksAllPages().then(secondResponse => {
                        const subStocksByStock = secondResponse.data.items.reduce((acc, subStock) => {
                            const stockId = subStock.stock_id;
                            if (!acc[stockId]) {
                                acc[stockId] = [];
                            }
                            acc[stockId].push(subStock);
                            return acc;
                        }, {});

                        response.data.items.forEach(stock => {
                            const stockSubStocks = subStocksByStock[stock.id] ?? [];
                            if (stockSubStocks.length) {
                                this.substocks.push(
                                    {header: stock.name},
                                    {divider: true}
                                );
                            }
                            this.substocks.push(
                                ...stockSubStocks.map(el => ({
                                    text: el.name,
                                    value: el.id,
                                    ownerId: el.owner.id,
                                    stockId: stock.id
                                }))
                            );
                        });
                    }).catch(this.snack)
                        .finally(() => this.loadingSubstocks = false);
                }).catch(err => {
                    this.snack(err);
                    this.loadingSubstocks = false;
                });
            },
            fetchCarriers: function () {
                this.loadingCarriers = true;
                ShipmentCarrierAPI.getAll()
                    .then(response => this.carriers = response.data.map(el => ({
                        text: el.name,
                        value: el.id
                    })))
                    .finally(() => this.loadingCarriers = false);
            },
            onSubStockFiltered: function (filteredSubstocks) {
                if (filteredSubstocks === null || filteredSubstocks === undefined) {
                    this.subStockId = null;
                    return;
                }
                if (filteredSubstocks.length === 1) {
                    this.subStockId = filteredSubstocks[0];
                    return;
                }
                this.subStockId = null;
            },
            onCarrierFiltered: function (filteredCarriers) {
                if (filteredCarriers === null || filteredCarriers === undefined) {
                    this.carrierId = null;
                    return;
                }
                if (filteredCarriers.length === 1) {
                    this.carrierId = filteredCarriers[0];
                    return;
                }
                this.carrierId = null;
            },
            showShipment: function (shipment) {
                if (shipment.id !== this.shipmentId) {
                    this.$router.push({
                        path: this.$route.path,
                        query: {shipmentId: shipment.id}
                    });
                }
            },
            printShipmentLabel: function (shipment) {
                this.loading = true;
                ShipmentAPI.printLabel(shipment.id)
                    .then(response => {
                        const printType = shipment.carrier_service ? shipment.carrier_service.type : PrintType.ZPL;
                        Export.print(response.data.url, 'shipment_' + shipment.id, printType);
                    }).catch(this.snack)
                    .finally(() => {
                        this.loading = false;
                    });
            },
            createNewProtocol: function (carrierId, ownerId, subStockId, shipmentIds) {
                ShipmentAPI.createProtocol(carrierId, ownerId, subStockId, shipmentIds, false)
                    .then(response => {
                        const protocolId = response.headers.location.split('/').pop();
                        ShipmentAPI.getProtocol(carrierId, ownerId, protocolId).then(response => {
                            Export.print(response.data.protocol_link, 'carrier_' + carrierId + '_substock' + subStockId + '_shipment_protocol');
                        }).catch(this.snack)
                            .finally(() => this.loading = false);
                    }).catch(err => {
                        this.snack(err);
                        this.loading = false;
                    });
            },
            printShipmentsDetail: function (shipmentIds) {
                if (!shipmentIds.length) {
                    return;
                }
                this.loading = true;
                const substock = this.substocks.find(substock => substock.value === this.subStockId);
                // check protocol existence
                ShipmentAPI.getAllProtocolsAllPages(substock.stockId, {
                    filter: APIFilters.makeFilter({
                        [APIFilterOP.AND]: [
                            ...shipmentIds.map(id => ({[APIFilterOP.ARRAY_CONTAINS]: {'shipment.ids': id}}))
                        ]
                    }),
                    sort: APIFilters.makeSort({created_at: 'DESC'})
                }).then(response => {
                    if (response.data.items.length) {
                        // all protocols which contain requested shipments returned
                        const requestedSet = new Set(shipmentIds.map(id => id.toString()));
                        for (const protocol of response.data.items) {
                            // need to compare sets because item order may wary
                            if (isEqual(requestedSet, new Set(protocol.shipment_ids))) {
                                // exact match of shipment ids
                                const protocolId = protocol.protocol_id;
                                ShipmentAPI.getProtocol(this.carrierId, substock.ownerId, protocolId).then(response => {
                                    Export.print(response.data.protocol_link, 'carrier_' + this.carrierId + '_substock' + this.subStockId + '_shipment_protocol');
                                }).catch(this.snack)
                                    .finally(() => this.loading = false);
                                return;
                            }
                        }
                        // no protocol with exact same shipments found - create new
                        this.createNewProtocol(this.carrierId, substock.ownerId, this.subStockId, shipmentIds);
                    } else {
                        // create new protocol
                        this.createNewProtocol(this.carrierId, substock.ownerId, this.subStockId, shipmentIds);
                    }
                }).catch(() => {
                    // GET of protocols failed, try to create new protocol
                    this.createNewProtocol(this.carrierId, substock.ownerId, this.subStockId, shipmentIds);
                }).finally(() => this.loading = false);
            },
            trackShipment: function (item) {
                window.open(item.tracking_link, "_blank");
            },
            hasCarrierErrors: function (item) {
                return item.last_validated === null
                    || (item.carrier_api_error_messages && item.carrier_api_error_messages.length > 0);
            },
            itemSelectColor: function (item) {
                return item.state === ShipmentState.WAITING_FOR_PICKUP ? 'primary' : 'accent';
            }
        }
    };
</script>

<style scoped>

</style>
