<template>
  <div v-show="!loading">
    <v-card-title v-if="!hideHeader">
      {{ title }}
      <v-icon
        v-if="!readOnly"
        class="ml-2"
        color="muted"
        @click="hint=!hint"
      >
        $info
      </v-icon>
    </v-card-title>
    <v-card-subtitle v-if="hint">
      {{ $t(hintPath) }}
    </v-card-subtitle>
    <v-form
      ref="form"
      v-model="valid"
    >
      <FormFields
        :form="form"
        :render="requiredParameters"
      />
    </v-form>
    <CarrierServiceParametersValues
      v-if="carrierServiceParameters && carrierServiceParameters.length !== 0"
      :carrier-service-parameters="carrierServiceParameters"
      :read-only="readOnly"
      class="mx-5"
    >
      <template #title>
        <div>
          <div class="font-weight-bold">
            {{ carrierName + " - " + carrierServiceName }}
          </div>
          {{ $t('tasks.externalOrder.carrierServiceParameters.title') }}:
        </div>
      </template>
    </CarrierServiceParametersValues>
    <WarnText
      v-if="!isValid && !loading && !readOnly"
      text="imports.info.parameters_warn"
    />
    <v-expansion-panels
      v-if="optionalParameters && Object.keys(optionalParameters).length"
      v-model="optionalParametersDropdown"
      popout
      class="pb-3 mx-3"
    >
      <v-expansion-panel :key="0">
        <v-expansion-panel-header>
          <div class="text-h6 font-weight-bold">
            {{ $t('carriers.contracted.parameters.optional.name') }}
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <FormFields
            :form="form"
            :render="optionalParameters"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-divider />
  </div>
</template>

<script>
    import FormFields from "@/app/components/form/FormFields.component";
    import {ParametersForm, ParametersRender} from "@/app/exports/definitions/parameters.form";
    import WarnText from "@/app/components/WarnText";
    import {Domains, Parameters} from "@/enum/domains";
    import {ShipmentCarrierAPI} from "@/api/ShipmentCarrierAPI";
    import {StockAPI} from "@/api/StockAPI";
    import CarrierServiceParametersValues from "@/app/tasks/externalOrder/components/CarrierServiceParametersValues.component";
    import {ParameterDefinitions} from "@/app/exports/definitions/parameters.form";
    import {BaseAPI} from "@/api/BaseAPI";
    import {ImportsAPI} from "@/api/ImportsAPI";

    export default {
        name: "Parameters",
        components: {CarrierServiceParametersValues, WarnText, FormFields},
        props: {
            title: {
                type: String,
                default: 'Title'
            },
            hintPath: {
                type: String,
                default: 'exports.info.parameters_hint'
            },
            isExport: {
                type: Boolean,
                default: false,
            },
            parameterValues: {
                type: Array,
                default: null
            },
            readOnly: {
                type: Boolean,
                default: false
            },
            hideHeader: {
                type: Boolean,
                default: false
            },
            domain: {
                type: String,
                default: ''
            },
            showOptional: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            loading: false,
            form: null,
            formRender: null,
            hint: false,
            parametersDefinition: null,
            carrierServiceParameters: null,
            carrierName: '',
            carrierServiceName: '',
            valid: false,
            optionalParametersDropdown: null
        }),
        computed: {
            isValid() {
                return this.checkParameters();
            },
            requiredParameters() {
                if (!this.form || !this.formRender) {
                    return;
                }
                return Object.fromEntries(Object.entries(this.formRender)
                    .filter(input => input[1].required));
            },
            optionalParameters() {
                if (!this.form || !this.formRender) {
                    return;
                }
                return Object.fromEntries(Object.entries(this.formRender)
                    .filter(input => !input[1].required));
            }
        },
        watch: {
            'form.stockId': function (newVal, oldVal) {
                if (oldVal && newVal === null) {
                    this.clearDependentValues(Parameters.STOCK);
                }
                if (this.form[Parameters.STOCK]) {
                    this.setFormXAutoComplete(Parameters.SUBSTOCK, StockAPI.getAllSubstocksForStock.bind(StockAPI, newVal));
                    this.setFormXAutoComplete(Parameters.SUBSTOCKS, StockAPI.getAllSubstocksForStock.bind(StockAPI, newVal));
                    this.setFormXAutoComplete(Parameters.LOCATIONS, StockAPI.getAllLocations.bind(StockAPI, newVal));

                }
            },
            'form.subordinate_stock_id': function (newVal, oldVal) {
                if (this.form[Parameters.SUBSTOCK] && this.form[Parameters.CARRIER] !== undefined) {
                    if (this.formRender[Parameters.CARRIER]) {
                        this.formRender[Parameters.CARRIER].xAutocomplete.apiDataSource = ShipmentCarrierAPI.getAllContractedBySubStock.bind(ShipmentCarrierAPI, newVal);
                        this.formRender[Parameters.CARRIER].xAutocomplete.resultTransform = el => ({
                            text: el.carrier.name,
                            value: el.carrier.id
                        });
                    }
                }
                if (oldVal && newVal === null) {
                    this.clearDependentValues(Parameters.SUBSTOCK);
                }
            },
            'form.carrier_id': function (newVal, oldVal) {
                if (oldVal !== null && newVal !== oldVal) {
                    this.clearDependentValues(Parameters.CARRIER);
                }
                if (this.form[Parameters.CARRIER] && this.form[Parameters.CARRIER_SERVICE] !== undefined) {
                    this.setFormXAutoComplete(Parameters.CARRIER_SERVICE, ShipmentCarrierAPI.getServices.bind(ShipmentCarrierAPI, newVal));
                }
                if (newVal !== null) {
                    ShipmentCarrierAPI.get(newVal)
                        .then(res => this.carrierName = res.data.name)
                        .catch(err => this.snack(err));
                } else {
                    this.carrierName = '';
                }
            },
            'form.carrier_service_id': function (newVal, oldVal) {
                if (newVal !== undefined && newVal !== null && !this.loading) {
                    ShipmentCarrierAPI.getService(this.form[Parameters.CARRIER], newVal)
                        .then(res => {
                            this.carrierServiceName = res.data.name;
                            this.carrierServiceParameters = res.data.carrier_service_parameters;
                        })
                        .catch(err => this.snack(err));
                } else if (oldVal && newVal === null) {
                    this.carrierServiceParameters = null;
                    this.carrierServiceName = '';
                }
            },
            'form.interval': function (newVal, oldVal) {
                if (newVal !== oldVal) {
                    this.formRender[Parameters.INTERVAL_FROM].intervalPicker.interval = newVal;
                    this.formRender[Parameters.INTERVAL_TO].intervalPicker.interval = newVal;
                    if (!this.loading) {
                        this.form[Parameters.INTERVAL_FROM] = null;
                        this.form[Parameters.INTERVAL_TO] = null;
                    }
                }
            },
            'form.interval_from': function () {
                this.checkDates(true);
            },
            'form.interval_to': function () {
                this.checkDates(true);
            },
            'form.date_from': function () {
                this.checkDates();
            },
            'form.date_to': function () {
                this.checkDates();
            },
            valid: function () {
                this.$emit('validParameters', this.checkParameters());
            },
            form: {
                deep: true,
                handler: function () {
                    const returnParameters = Object.entries(this.form)
                        .filter(item => this.parametersDefinition.find(definition => definition.key === item[0]) !== undefined)
                        .map(parameter => {
                            return {
                                key: parameter[0],
                                value: parameter[1]
                            };
                        });
                    returnParameters.filter(item => ParameterDefinitions[item.key].type === '[Number]'
                        || ParameterDefinitions[item.key].type === '[String]')
                        .forEach(item => {
                            if (item.value !== null && item.value !== '') {
                                item.value = item.value.join(',');
                            }
                        }
                        );
                    this.$emit('emitParameters', returnParameters);
                    this.$emit('validParameters', this.checkParameters());
                }
            },
            carrierServiceParameters: {
                deep: true,
                handler: function () {
                    if (!this.loading) {
                        const returnValues = this.carrierServiceParameters?.filter(parameter => parameter.value || parameter.required);
                        this.form[Parameters.CARRIER_SERVICE_PARAMETERS] = JSON.stringify(returnValues ?? '');
                    }
                }
            }
        },
        createdOrActivated() {
            this.loading = true;
            if (this.showOptional) {
                this.optionalParametersDropdown = 0;
            }
            this.getTemplate();
        },
        methods: {
            getTemplate() {
                ImportsAPI.getTemplate(this.domain)
                    .then(res => {
                        this.parametersDefinition = this.isExport ? res.data.export_parameters : res.data.import_parameters;
                        this.form = new ParametersForm(this.parametersDefinition);
                        this.formRender = new ParametersRender(this.parametersDefinition, this.readOnly);
                        if (this.parameterValues && this.parameterValues.length > 0) {
                            this.fillForm();
                        } else {
                            this.loading = false;
                        }
                    });
            },
            checkParameters() {
                if (!this.form) {
                    return false;
                }
                if (!this.valid) {
                    return false;
                }
                return !Object.entries(this.form).filter(item => ParameterDefinitions[item[0]].required).some(parameter => {
                    if (parameter[0] === Parameters.CARRIER_SERVICE_PARAMETERS) {
                        const val = JSON.parse(parameter[1]);
                        return !val ? false : val.some(service_parameter => !service_parameter.value && service_parameter.required);
                    } else {
                        return parameter[1] === '' || parameter[1] === null;
                    }
                });
            },
            fillForm() {
                const promises = [];
                Object.keys(this.form).forEach(item => {
                    const parameter = this.parameterValues.find(parameter => parameter.key === item);
                    let valArray = [];
                    if (parameter && parameter.value !== null) {
                        switch (parameter.key) {
                        case Parameters.SUBSTOCK :
                            promises.push(this.setStock(Number(parameter.value)));
                            this.form[Parameters.SUBSTOCK] = Number(parameter.value);
                            break;
                        case Parameters.SUBSTOCKS :
                            valArray = parameter.value.split(',').map(item => parseInt(item, 10));
                            promises.push(this.setStock(valArray[0]));
                            this.form[Parameters.SUBSTOCKS] = valArray;
                            break;
                        case Parameters.MANUFACTURERS :
                            valArray = parameter.value.split(',').map(item => parseInt(item, 10));
                            this.form[Parameters.MANUFACTURERS] = valArray;
                            break;
                        case Parameters.PRODUCT_TYPES :
                            valArray = parameter.value.split(',').map(item => parseInt(item, 10));
                            this.form[Parameters.PRODUCT_TYPES] = valArray;
                            break;
                        case Parameters.PRODUCTS :
                            valArray = parameter.value.split(',').map(item => parseInt(item, 10));
                            this.form[Parameters.PRODUCTS] = valArray;
                            break;
                        case Parameters.LOCATIONS :
                            valArray = parameter.value.split(',').map(item => parseInt(item, 10));
                            this.form[Parameters.LOCATIONS] = valArray;
                            break;
                        case Parameters.CARRIER_SERVICE:
                            promises.push(this.setCarrierServices(Number(parameter.value)));
                            break;
                        case Parameters.CARRIER_SERVICE_PARAMETERS:
                            this.form[Parameters.CARRIER_SERVICE_PARAMETERS] = parameter.value;
                            this.setServiceParameters(JSON.parse(parameter.value));
                            break;
                        default :
                            if (ParameterDefinitions[parameter.key].type === '[String]'
                                || ParameterDefinitions[parameter.key].type === '[Number]')
                            {
                                this.form[parameter.key] = parameter.value.split(',');
                            } else {
                                this.form[parameter.key] = parameter.value;
                            }
                        }
                    }
                });
                Promise.allSettled(promises)
                    .then(() => this.loading = false);
            },
            async setStock(substockId) {
                if (this.form[Parameters.STOCK] === null) {
                    StockAPI.getSubstockWOStock(substockId)
                        .then(subStock => {
                            this.form[Parameters.STOCK] = subStock.data.stock_id;
                        })
                        .catch(err => this.snack(err));
                }
            },
            setCarrierServices: async function (carrierServiceId) {
                const carrier = {};
                const promises = [];
                await ShipmentCarrierAPI.getAll()
                    .then(res => {
                        const carriers = res.data;
                        carriers.forEach(item => {
                            promises.push(ShipmentCarrierAPI.getServices(item.id)
                                .then(response => {
                                    const services = response.data;
                                    services.forEach(service => {
                                        if (service.id === carrierServiceId) {
                                            carrier.id = item.id;
                                            carrier.services = services;
                                            this.carrierServiceName = service.name;
                                            this.setServiceParameters(service.carrier_service_parameters, true);
                                        }
                                    });
                                }));
                        });
                    });
                await Promise.all(promises)
                    .then(() => {
                        this.form[Parameters.CARRIER] = carrier.id;
                        this.form[Parameters.CARRIER_SERVICE] = carrierServiceId;
                    });
            },
            setFormXAutoComplete(formName, dataSource) {
                if (this.formRender[formName]) {
                    this.formRender[formName].xAutocomplete.apiDataSource = dataSource;
                }
            },
            getDependentParameters(parameterName) {
                return Object.entries(ParameterDefinitions).filter(definition => {
                    return definition[1].require && definition[1].require.includes(parameterName);
                }).map(parameter => parameter[0]);
            },
            clearDependentValues(parameterName) {
                this.getDependentParameters(parameterName).forEach(param => {
                    if (this.form[param] !== undefined) {
                        this.form[param] = null;
                        this.formRender[param].xAutocomplete.apiDataSource = () => BaseAPI.emptyDataSource();
                    }
                });
            },
            setServiceParameters(newParameters, isDefinition = false) {
                if (!this.carrierServiceParameters) {
                    this.carrierServiceParameters = newParameters;
                    return;
                } else if (isDefinition) {
                    const currentParameters = this.carrierServiceParameters;
                    this.carrierServiceParameters = newParameters;
                    newParameters = currentParameters;
                }
                this.carrierServiceParameters = this.carrierServiceParameters.map(parameter => {
                    return newParameters.find(newParameter => newParameter.name === parameter.name) ?? parameter;
                });
            },
            checkDates: function (isInterval = false) {
                const from = isInterval ? this.form[Parameters.INTERVAL_FROM] : this.form[Parameters.FROM_DATE];
                const to = isInterval ? this.form[Parameters.INTERVAL_TO] : this.form[Parameters.TO_DATE];
                const toRender = isInterval ? this.formRender[Parameters.INTERVAL_TO] : this.formRender[Parameters.TO_DATE];

                const difference = this.$moment(to).diff(this.$moment(), 'days');
                if (difference > 0) {
                    toRender.errors = [this.$t('base.form.rules.mustBeUpToToday')];
                    return;
                } else {
                    toRender.errors = [];
                }
                if (from && to) {
                    const difference = this.$moment(to).diff(this.$moment(from), 'seconds');
                    if (difference < 0) {
                        toRender.errors = [this.$t('base.form.rules.mustBeHigherThanFrom')];
                    } else if (difference > (90 * 3600 * 24) &&
                        (this.domain === Domains.INTERVAL_MOVEMENTS_REPORT || this.domain === Domains.SHARED_LOCATIONS_REPORT)) {
                        toRender.errors = [this.$t('base.form.rules.maxDays', ['90'])];
                    } else {
                        toRender.errors = [];
                    }
                }
            },
        }
    };
</script>
