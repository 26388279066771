<template>
  <v-alert
    outlined
    type="error"
    class="d-flex flex-column"
  >
    <div>
      {{ message }}
    </div>
    <div
      class="flex-wrap"
    >
      <template v-if="type === 'blocking_tasks'">
        <v-btn
          v-for="task of details"
          :key="task.id"
          color="error"
          text
          :to="taskLinks[task.type] + '/' + task.id"
        >
          #{{ task.id }} {{ $t(taskNames[task.type]) }}
          <v-icon class="ml-2">
            $openItem
          </v-icon>
        </v-btn>
      </template>
      <template v-if="type === 'items_at_location'">
        <v-btn
          v-for="item of details"
          :key="item.stock_location.id + '-' + item.product_instance.id"
          color="error"
          text
        >
          {{ item.quantity }}{{ item.product_instance | productMeasureLabel }}
          {{ item.product_instance | instanceLabel }}
          <v-icon class="ml-2">
            $openItem
          </v-icon>
        </v-btn>
      </template>
      <template v-if="type === 'missing_item'">
        <v-btn
          color="error"
          text
        >
          {{ details.quantity }}{{ details.product_instance | productMeasureLabel }}
          {{ details.product_instance | instanceLabel }}
          <v-icon class="ml-2">
            $openItem
          </v-icon>
        </v-btn>
      </template>
      <template v-if="type === 'location_not_allowed_for_substock'">
        <v-btn
          color="error"
          text
          :to="'/stocks/' + details.stock_id + '/substocks/' + details.id"
        >
          {{ details.name }}
          <v-icon class="ml-2">
            $openItem
          </v-icon>
        </v-btn>
      </template>
      <template v-if="type === 'not_allowed_sub_stocks'">
        <v-btn
          v-for="substock of details"
          :key="substock.id"
          color="error"
          text
          :to="'/users/' + userId + '?stock_id=' + substock.stock_id"
        >
          {{ substock.name }}
          <v-icon class="ml-2">
            $openItem
          </v-icon>
        </v-btn>
      </template>
    </div>
  </v-alert>
</template>

<script>
    import {taskLinks, taskNames} from "@/enum/task_type";

    export default {
        name: "TaskConflict",
        props: {
            error: {
                type: Object,
                default: () => ({
                    type: null,
                    message: ''
                })
            }
        },
        data: () => ({
            taskLinks: taskLinks,
            taskNames: taskNames
        }),
        computed: {
            type: function () {
                return this.error.type;
            },
            message: function () {
                return this.error.message;
            },
            details: function () {
                const referenceErrorTypes = ['blocking_tasks', 'not_allowed_sub_stocks'];
                if (referenceErrorTypes.includes(this.type)) {
                    return this.error['references'];
                }
                return this.error[this.type];
            },
            userId: function () {
                return this.$store.getters['oauth/getUserId'];
            }
        }
    };
</script>

<style scoped>

</style>
