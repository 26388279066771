<template>
  <div>
    <x-toolbar />
    <TaskConflict
      v-if="conflict"
      :error="conflict"
    />
    <v-card>
      <v-container fluid>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent
        >
          <FormFields
            ref="fields"
            :form="form"
            :render="formRender"
            lang-path="tasks.stockTaking."
          >
            <template #restrict_type-item="{ item }">
              <v-list-item-icon class="mr-3">
                <v-icon>{{ RestrictionIcon[item.value] }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                {{ item.text }}
              </v-list-item-content>
            </template>
          </FormFields>
          <v-flex
            v-if="showRegexInputItemsFilter"
            xs12
          >
            <v-divider class="my-3" />
            <RegexInputItemsFilter
              :title="$t('tasks.stockTaking.entityHelper.label', [$t('tasks.stockTaking.restrictions.' + form.restrict_type)])"
              :submit-label="$t('tasks.stockTaking.entityHelper.set', [$t('tasks.stockTaking.restrictions.' + form.restrict_type)])"
              :items="formRender.restrict_references.autocomplete.items"
              @input="selectedItems => form.restrict_references = selectedItems"
            />
          </v-flex>
          <v-flex
            v-if="form.stock_id"
            xs12
          >
            <v-divider
              class="my-3"
            />
            <TaskAttributes
              :task-type="type"
              :stock-id="form.stock_id"
              :submit-trigger="attributesSubmitTrigger"
              inline
              @update:valid="val => validAttributes = val"
              @update-submit-callback="callback => attributesSubmitCallback = callback"
            />
          </v-flex>
          <v-flex
            xs12
          >
            <v-divider class="my-3" />
            <TaskAttachmentUpload
              v-model="validAttachments"
              :files-to-attach="form.attachments"
            />
          </v-flex>
          <v-flex
            xs12
          >
            <v-divider class="my-3" />
            <v-btn
              :text="!validAll"
              :loading="loading"
              type="submit"
              color="accent"
              @click="submit()"
            >
              <v-icon
                class="mr-2"
              >
                $taskStockTaking
              </v-icon>
              {{ $t('tasks.stockTaking.create') }}
            </v-btn>
          </v-flex>
        </v-form>
      </v-container>
    </v-card>
  </div>
</template>

<script>
    import {
        StockTakingCreateForm,
        StockTakingCreateRender
    } from "@/app/tasks/stockTaking/definitions/stockTakingCreate.form";
    import {formMountedProcedure, setFormErrors} from "@/utils/form";
    import FormFields from "@/app/components/form/FormFields.component";
    import {StockAPI} from "@/api/StockAPI";
    import {
        TaskStockTakingRestriction as RestrictionType,
        TaskStockTakingRestrictionIcon as RestrictionIcon
    } from "@/enum/task_stock_taking_restriction";
    import {ProductAPI} from "@/api/ProductAPI";
    import {ManufacturerAPI} from "@/api/ManufacturerAPI";
    import {TaskStockTakingAPI} from "@/api/TaskStockTakingAPI";
    import {taskLinks, taskTypes} from "@/enum/task_type";
    import TaskAttachmentUpload from "@/app/tasks/components/TaskAttachmentUpload.component";
    import {locationLabel} from "@/utils/string";
    import {getIdFromLocation} from "@/utils/url";
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";
    import {instanceLabel, productLabel} from "@/utils/filters";
    import {has} from "@/utils/object";
    import TaskConflict from "@/app/tasks/components/TaskConflict.component.vue";
    import RegexInputItemsFilter from "@/app/components/RegexInputItemsFilter.component.vue";
    import TaskAttributes from "@/app/tasks/components/taskAttributes/TaskAttributes.component.vue";

    export default {
        name: "StockTakingCreate",
        components: {TaskAttributes, RegexInputItemsFilter, TaskConflict, TaskAttachmentUpload, FormFields},
        mixins: [RouteParamsMapperMixin],
        data: () => ({
            type: taskTypes.STOCK_TAKING,
            form: new StockTakingCreateForm,
            formRender: new StockTakingCreateRender,
            valid: true,
            validAttachments: true,
            validAttributes: true,
            loading: false,
            restrict_reference_items: {
                [RestrictionType.SUB_STOCK]: [],
                [RestrictionType.STOCK_LOCATION]: [],
                [RestrictionType.PRODUCT]: [],
                [RestrictionType.PRODUCT_INSTANCE]: [],
                [RestrictionType.MANUFACTURER]: [],
                [RestrictionType.STOCK_OWNER]: []
            },
            productInstances: {},
            RestrictionIcon: RestrictionIcon,
            conflict: null,
            productSetFromQuery: false,
            attributesSubmitTrigger: 0,
            attributesSubmitCallback: null,
        }),
        computed: {
            validAll: function () {
                return this.valid && this.validAttachments && this.validAttributes;
            },
            showRegexInputItemsFilter: function () {
                return this.form.restrict_type === RestrictionType.STOCK_LOCATION
                    || this.form.restrict_type === RestrictionType.PRODUCT
                    || this.form.restrict_type === RestrictionType.PRODUCT_INSTANCE;
            }
        },
        watch: {
            'form.restrict_type': function (newValue) {
                if (newValue !== undefined && newValue !== null) {
                    if (newValue !== RestrictionType.STOCK) {
                        this.formRender.restrict_references.show = true;
                        if (this.restrict_reference_items[newValue].length === 0) {
                            this.loadRestrictions();
                        } else {
                            this.$set(this.formRender.restrict_references.autocomplete, 'items', this.restrict_reference_items[newValue]);
                        }
                        if (newValue !== RestrictionType.PRODUCT_INSTANCE) {
                            if (!this.productSetFromQuery) {
                                this.form.restrict_references = [];
                            } else {
                                this.productSetFromQuery = false;
                            }
                            this.formRender.product.show = false;
                        } else {
                            this.$set(this.formRender.product.autocomplete, 'items', this.restrict_reference_items[RestrictionType.PRODUCT]);
                            this.formRender.product.show = true;
                        }
                    } else {
                        this.formRender.restrict_references.show = false;
                    }
                    this.formRender.restrict_references.icon = RestrictionIcon[newValue];
                }
            },
            'form.product': function (productId) {
                const type = RestrictionType.PRODUCT_INSTANCE;
                let promise;
                if (!has(this.productInstances, productId)) {
                    const product = this.restrict_reference_items[RestrictionType.PRODUCT].find(el => el.value === productId);
                    promise = ProductAPI.getAllInstancesAllPages(productId).then(response => {
                        const newInstances = response.data.items.map(el => ({
                            text: instanceLabel(el, product),
                            value: el.id
                        }));
                        this.$set(this.productInstances, productId, newInstances);
                        return newInstances;
                    });
                } else {
                    promise = Promise.resolve(this.productInstances[productId]);
                }
                promise.then(instances => {
                    const currentInstances = (this.restrict_reference_items[type] || [])
                        .filter(el => this.form.restrict_references.includes(el.value));
                    this.setRestrictionItems(type, instances.concat(currentInstances));
                });
            },
            'form.stock_id': function (newValue) {
                if (newValue !== undefined && newValue !== null) {
                    this.stockIdChanged();
                }
            }
        },
        createdOrActivated: function () {
            this.formRender.restrict_references.show = false;
            this.productInstances = {};
            this.conflict = null;

            if (this.productId !== undefined) {
                this.form.restrict_type = RestrictionType.PRODUCT;
                this.form.restrict_references = [this.productId];
                this.productSetFromQuery = true;
                this.formRender.restrict_references.show = true;
            }
        },
        mounted: function () {
            formMountedProcedure(this);
        },
        methods: {
            stockIdChanged: function () {
                const type = this.form.restrict_type;
                if (type === RestrictionType.STOCK_LOCATION) {
                    if (this.form.stock_id !== null) {
                        this.loadEntities(type, StockAPI.getAllLocationsAllPages(this.form.stock_id), locationLabel);
                    }
                }
                if (type === RestrictionType.SUB_STOCK) {
                    if (this.form.stock_id !== null) {
                        this.loadEntities(type, StockAPI.getAllSubstocksForStockAllPages(this.form.stock_id), this.nameLabel);
                    }
                }
            },
            loadRestrictions: function () {
                const type = this.form.restrict_type;
                switch (type) {
                case RestrictionType.STOCK_LOCATION:
                    if (this.form.stock_id !== null) {
                        this.loadEntities(type, StockAPI.getAllLocationsAllPages(this.form.stock_id), locationLabel);
                    }
                    break;
                case RestrictionType.SUB_STOCK:
                    if (this.form.stock_id !== null) {
                        this.loadEntities(type, StockAPI.getAllSubstocksForStockAllPages(this.form.stock_id), this.nameLabel);
                    }
                    break;
                case RestrictionType.PRODUCT:
                case RestrictionType.PRODUCT_INSTANCE:
                    // load products for product and instances as well
                    this.loadEntities(RestrictionType.PRODUCT, ProductAPI.getVisibleAllPages(), productLabel, true);
                    break;
                case RestrictionType.MANUFACTURER:
                    this.loadEntities(type, ManufacturerAPI.getAllPages(), this.nameLabel);
                    break;
                case RestrictionType.STOCK_OWNER:
                    this.loadEntities(
                        type,
                        StockAPI.getAllOwners().then(response => {
                            response.data.items = response.data;
                            return response;
                        }),
                        this.nameLabel);
                    break;
                }
            },
            loadEntities: function (type, apiCall, labelFilter, includeAllProperties = false) {
                this.$set(this.formRender.restrict_references, 'loading', true);
                apiCall.then(response => {
                    this.setRestrictionItems(type, response.data.items.map(el => {
                        let autocompleteItem = {};
                        if (includeAllProperties) {
                            autocompleteItem = el;
                        }
                        autocompleteItem = {
                            ...autocompleteItem,
                            text: labelFilter(el),
                            value: el.id
                        };
                        return autocompleteItem;
                    }));
                }).finally(() => {
                    this.$set(this.formRender.restrict_references, 'loading', false);
                });
            },
            setRestrictionItems: function (type, items) {
                this.restrict_reference_items[type] = items;
                if (this.form.restrict_type === type) {
                    this.$set(this.formRender.restrict_references.autocomplete, 'items', items);
                } else {
                    this.$set(this.formRender.restrict_references.autocomplete, 'items', this.restrict_reference_items[this.form.restrict_type]);
                }
                if (type === RestrictionType.PRODUCT) {
                    this.$set(this.formRender.product.autocomplete, 'items', items);
                }
            },
            submit: function () {
                if (!this.validAll) {
                    this.$refs.form.validate();
                    return;
                }
                this.loading = true;
                this.attributesSubmitTrigger++;
                TaskStockTakingAPI.create(this.form)
                    .then(response => {
                        this.conflict = null;
                        const taskId = getIdFromLocation(response);
                        this.attributesSubmitCallback(taskId)
                            .then(() => {
                                this.snack('tasks.stockTaking.createDone');
                                this.$route.meta.uuid = this.$route.meta.uuid + 1 || 1;
                                this.$router.push(taskLinks[this.type] + '/' + taskId);
                            }).catch(this.snack)
                            .finally(() => this.loading = false);
                    }).catch(err => {
                        if (err.response && err.response.status === 409) {
                            this.conflict = err.response.data;
                        } else {
                            setFormErrors.bind(this, err);
                        }
                        this.loading = false;
                    });
            },
            nameLabel: function (el) {
                return el.name;
            }
        }
    };
</script>

<style scoped>

</style>
