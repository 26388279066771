import { render, staticRenderFns } from "./RackSetupPanel.component.vue?vue&type=template&id=8540b0aa&scoped=true"
import script from "./RackSetupPanel.component.vue?vue&type=script&lang=js"
export * from "./RackSetupPanel.component.vue?vue&type=script&lang=js"
import style0 from "./RackSetupPanel.component.vue?vue&type=style&index=0&id=8540b0aa&prod&scoped=true&lang=sass"


/* normalize component */
import normalizer from "!../../../../../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.11.0_webpack@5.96.1__lodash@4.17.21_vue-template-compiler@2.7.16_webpack@5.96.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8540b0aa",
  null
  
)

export default component.exports