<template>
  <div>
    <v-autocomplete
      v-model="
        /* eslint-disable-next-line vue/no-mutating-props */
        item.props.subStockIds
      "
      chips
      hide-details
      :items="substocks"
      :label="$t('homepage.config.onlySubstocks')"
      :loading="loadingSubstocks"
      multiple
      outlined
      prepend-icon="$filter"
    />
  </div>
</template>

<script>
    import {StockAPI} from "@/api/StockAPI";

    export default {
        name: "DashboardOrdersStatsConfig",
        props: {
            item: {
                type: Object,
                default: () => ({})
            }
        },
        data: () => ({
            chosenSubstocks: [],
            substocks: [],
            loadingSubstocks: false
        }),
        createdOrActivated: function () {
            this.fetchSubstocks();
        },
        methods: {
            fetchSubstocks: function () {
                this.loadingSubstocks = true;
                this.substocks = [];
                StockAPI.getAllPages().then(response => {
                    StockAPI.getAllSubstocksAllPages().then(secondResponse => {
                        const subStocksByStock = secondResponse.data.items.reduce((acc, subStock) => {
                            const stockId = subStock.stock_id;
                            if (!acc[stockId]) {
                                acc[stockId] = [];
                            }
                            acc[stockId].push(subStock);
                            return acc;
                        }, {});

                        response.data.items.forEach(stock => {
                            const stockSubStocks = subStocksByStock[stock.id] ?? [];
                            if (stockSubStocks.length) {
                                this.substocks.push(
                                    {header: stock.name},
                                    {divider: true}
                                );
                            }
                            this.substocks.push(
                                ...stockSubStocks.map(el => ({
                                    text: el.name,
                                    value: el.id,
                                    ownerId: el.owner.id,
                                    stockId: stock.id
                                }))
                            );
                        });
                    }).catch(this.snack)
                        .finally(() => this.loadingSubstocks = false);
                }).catch(err => {
                    this.snack(err);
                    this.loadingSubstocks = false;
                });
            }
        }
    };
</script>

<style scoped>

</style>
