<template>
  <div class="d-flex flex-wrap">
    <span
      v-for="(field, key) in render"
      :key="key"
      class="mx-3 d-flex justify-center"
      :class="{ 'hidden': !(field.show === undefined || (typeof field.show === 'function' ? field.show(form) : field.show)) }"
      :style="(stretchForm ? 'width: calc('+ (field.stretchCoefficient || 1)*100 +'% - 24px)' + '; min-' : '')
        + 'width: calc(' + 20 * (field.sizeCoefficient || 1) + 'em - 24px)' /* 24 px has to be same as mx-3 above */"
    >
      <h3
        v-if="field.header === true"
        class="mt-2"
      >
        {{ $t(langPath + key) }}
      </h3>
      <slot
        v-else-if="$scopedSlots[key] !== undefined"
        :field-render="field"
        :name="key"
      />
      <x-autocomplete
        v-if="field.xAutocomplete !== undefined"
        :ref="key"
        v-model="
          /* eslint-disable-next-line vue/no-mutating-props */
          form[key]
        "
        :append-outer-icon="field.help !== undefined ? 'help_outline' : ''"
        :api-source="field.xAutocomplete"
        :all-item-label="(field.required === true) ? undefined : field.xAutocomplete.allItemLabel"
        :clearable="!field.sticky"
        :disabled="field.readonly"
        :disable-autoselect-first="!!field.xAutocomplete.disableAutoselectFirst"
        :error-messages="field.errors"
        :hint="$t(field.hint)"
        :label="field.label ? field.label : (field.langPath ? $t(field.langPath + key) : $t(langPath + key))"
        :multiple="!!field.xAutocomplete.multiple"
        :chips="!!field.xAutocomplete.chips"
        :persistent-hint="field.hint !== undefined"
        :prepend-icon="field.icon"
        :readonly="field.readonly"
        :rules="getFieldRules(field)"
        :suffix="(field.required === true) ? '*' : ''"
        autocomplete="off"
        @change="clearUserErrors"
        @update:loading="val => $set(field, 'loading', val)"
        @click:append-outer.stop="helpClick(field.help)"
      >
        <template
          v-if="isChief && field.createNew !== undefined
            && (field.createNew.condition === undefined || field.createNew.condition(form))"
          #no-data
        >
          <v-list v-if="field.loading === false">
            <v-list-item
              @click="field.createNew.action"
            >
              <v-list-item-avatar>
                <v-icon>{{ field.createNew.icon }}</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ $t(field.createNew.label) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <span v-else>
            <!-- intentionally empty -->
          </span>
        </template>
        <template
          v-if="$scopedSlots[key + '-append-each-item'] !== undefined"
          #append-each-item="{item}"
        >
          <slot
            :name="key + '-append-each-item'"
            :item="item"
          />
        </template>
      </x-autocomplete>
      <component
        :is="field.autocomplete?.allowCustom ? 'v-combobox' : 'v-autocomplete'"
        v-else-if="field.autocomplete !== undefined"
        :ref="key"
        v-model="
          /* eslint-disable-next-line vue/no-mutating-props */
          form[key]
        "
        :append-outer-icon="field.help !== undefined ? 'help_outline' : ''"
        :chips="!!field.autocomplete.chips"
        :clearable="!field.sticky"
        :small-chips="field.autocomplete.chips"
        :disabled="field.readonly"
        :error-messages="field.errors"
        :hint="$t(field.hint)"
        :items="field.autocomplete.items"
        :item-text="field.autocomplete.render || 'text'"
        :label="field.label ? field.label : (field.langPath ? $t(field.langPath + key) : $t(langPath + key))"
        :loading="field.loading"
        :multiple="!!field.autocomplete.multiple"
        :persistent-hint="field.hint !== undefined"
        :prepend-icon="field.icon"
        :readonly="field.readonly"
        :rules="getFieldRules(field)"
        :suffix="(field.required === true) ? '*' : ''"
        autocomplete="off"
        @change="clearUserErrors"
        @click:append-outer.stop="helpClick(field.help)"
      >
        <template
          v-if="isChief && field.createNew !== undefined
            && (field.createNew.condition === undefined || field.createNew.condition(form))"
          #no-data
        >
          <v-list>
            <v-list-item
              @click="field.createNew.action"
            >
              <v-list-item-avatar>
                <v-icon>{{ field.createNew.icon }}</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ $t(field.createNew.label) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </template>
        <template
          v-if="$scopedSlots[key + '-item'] !== undefined"
          #item="{ item }"
        >
          <slot
            :name="key + '-item'"
            :item="item"
          />
        </template>
        <template
          v-else-if="field.tooltipHint"
          #append-outer
        >
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                class="pl-3"
                v-on="on"
              >
                $info
              </v-icon>
            </template>
            <span>{{ $t(field.tooltipHint) }}</span>
          </v-tooltip>
        </template>
      </component>
      <v-select
        v-else-if="field.select !== undefined"
        :ref="key"
        v-model="
          /* eslint-disable-next-line vue/no-mutating-props */
          form[key]
        "
        :append-outer-icon="field.help !== undefined ? 'help_outline' : ''"
        :disabled="field.readonly"
        :error-messages="field.errors"
        :hint="$t(field.hint)"
        :items="field.select"
        :label="field.label ? field.label : (field.langPath ? $t(field.langPath + key) : $t(langPath + key))"
        :persistent-hint="field.hint !== undefined"
        :prepend-icon="field.icon"
        :readonly="field.readonly"
        :rules="getFieldRules(field)"
        :suffix="field.required === true ? '*' : ''"
        autocomplete="off"
        @change="clearUserErrors"
        @click:append-outer.stop="helpClick(field.help)"
      >
        <template
          v-if="$scopedSlots[key + '-append-outer'] !== undefined"
          #append-outer
        >
          <slot :name="key + '-append-outer'" />
        </template>
        <template
          v-else-if="field.tooltipHint"
          #append-outer
        >
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                class="pl-3"
                v-on="on"
              >
                $info
              </v-icon>
            </template>
            <span>{{ $t(field.tooltipHint) }}</span>
          </v-tooltip>
        </template>
        <template
          v-if="field.valueLangPath"
          #selection="value"
        >
          {{ $t(field.valueLangPath + value.item) }}
        </template>
        <template
          v-if="$scopedSlots[key + '-item'] !== undefined"
          #item="{ item }"
        >
          <slot
            :name="key + '-item'"
            :item="item"
          />
        </template>
      </v-select>
      <v-textarea
        v-else-if="field.textarea === true"
        :ref="key"
        v-model="
          /* eslint-disable-next-line vue/no-mutating-props */
          form[key]
        "
        :append-outer-icon="field.help !== undefined ? 'help_outline' : ''"
        auto-grow
        :disabled="field.readonly"
        :error-messages="field.errors"
        :hint="$t(field.hint)"
        :label="field.label ? field.label : (field.langPath ? $t(field.langPath + key) : $t(langPath + key))"
        :name="key"
        outlined
        :persistent-hint="field.hint !== undefined"
        :prepend-icon="(field.icon)"
        :readonly="field.readonly"
        :rows="1"
        :rules="getFieldRules(field)"
        :suffix="field.required === true ? '*' : ''"
        @change="clearUserErrors"
        @click:append-outer.stop="helpClick(field.help)"
      />
      <v-checkbox
        v-else-if="field.checkbox === true"
        :ref="key"
        v-model="
          /* eslint-disable-next-line vue/no-mutating-props */
          form[key]
        "
        :append-icon="field.help !== undefined ? 'help_outline' : ''"
        color="secondary"
        :disabled="field.readonly"
        :error-messages="field.errors"
        :hint="$t(field.hint)"
        :label="field.label ? field.label : (field.langPath ? $t(field.langPath + key) : $t(langPath + key))"
        :name="key"
        :persistent-hint="field.hint !== undefined"
        :prepend-icon="(field.icon)"
        :readonly="field.readonly"
        @change="clearUserErrors"
        @click:append.stop="helpClick(field.help)"
      >
        <!-- has to replace label in order for icon to be directly after text.        -->
        <template
          v-if="$scopedSlots[key + '-append'] !== undefined"
          #label
        >
          <slot :name="key + '-append'" />
        </template>
      </v-checkbox>
      <FormDateTimePicker
        v-else-if="field.datePicker || field.dateTimePicker ||
          (field.intervalPicker && field.intervalPicker.interval === TimeIntervals.DAY)"
        v-model="
          /* eslint-disable-next-line vue/no-mutating-props */
          form[key]
        "
        v-bind="field"
        :end-of-day-time="!!field.endOfDayTime"
        :label="field.label ? field.label : (field.langPath ? $t(field.langPath + key) : $t(langPath + key))"
        :name="key"
        :time-picker="!!field.dateTimePicker"
        :disabled="field.readonly"
        @input="clearUserErrors"
        @clickHelp="helpClick(field.help)"
      >
        <template
          v-if="field.appendOuter !== undefined"
          #append-outer
        >
          <slot :name="key + '-append-outer'" />
        </template>
      </FormDateTimePicker>
      <FormIntervalPicker
        v-else-if="field.intervalPicker && field.intervalPicker.interval !== TimeIntervals.DAY"
        v-model="
          /* eslint-disable-next-line vue/no-mutating-props */
          form[key]
        "
        :interval="field.intervalPicker.interval"
        :is-interval-from="field.intervalPicker.isIntervalFrom"
        :allowed-day-of-week="field.intervalPicker.allowedDayOfWeek"
        v-bind="field"
        :label="field.label ? field.label : (field.langPath ? $t(field.langPath + key) : $t(langPath + key))"
        :name="key"
        :time-picker="!!field.dateTimePicker"
        :disabled="field.readonly"
        :required="field.required"
        @input="clearUserErrors"
        @clickHelp="helpClick(field.help)"
      >
        <template
          v-if="field.appendOuter !== undefined"
          #append-outer
        >
          <slot :name="key + '-append-outer'" />
        </template>
      </FormIntervalPicker>
      <v-file-input
        v-else-if="field.filePicker"
        v-model="
          /* eslint-disable-next-line vue/no-mutating-props */
          form[key]
        "
        v-bind="field"
        v-async-validate
        :label="field.label ? field.label : (field.langPath ? $t(field.langPath + key) : $t(langPath + key))"
        :name="key"
        :rules="getFieldRules(field)"
        :suffix="field.required === true ? '*' : ''"
        :async-rules="field.asyncRules"
        :disabled="field.readonly"
        @click="$emit('fileClick')"
      >
        <template
          v-if="field.appendOuter !== undefined"
          #append-outer
        >
          <slot :name="key + '-append-outer'" />
        </template>
      </v-file-input>
      <v-text-field
        v-else
        :ref="key"
        v-model.trim="
          /* eslint-disable-next-line vue/no-mutating-props */
          form[key]
        "
        :append-icon="field.type === 'password' ? (pwVisible[key] ? '$showItem' : '$hideItem') : undefined"
        :append-outer-icon="field.help !== undefined ? 'help_outline' : ''"
        :counter="field.max"
        :disabled="field.readonly"
        :error-messages="field.errors"
        :hint="$t(field.hint)"
        :label="field.label ? field.label : (field.langPath ? $t(field.langPath + key) : $t(langPath + key))"
        :loading="field.loading"
        :name="key"
        :persistent-hint="field.hint !== undefined"
        :prepend-icon="field.icon"
        :readonly="field.readonly"
        :rules="getFieldRules(field)"
        :suffix="field.required === true ? '*' : ''"
        :type="(field.type === undefined ? 'text'
          : (field.type === 'password' ? (pwVisible[key] ? 'text' : 'password')
            : field.type))"
        :min="field.min"
        :step="field.step"
        @change="clearUserErrors"
        @input="clearUserErrors"
        @blur="handleValue(key, field.type)"
        @click:append="pwVisibleClick(key)"
        @click:append-outer.stop="helpClick(field.help)"
      >
        <template
          v-if="field.appendOuter !== undefined"
          #append-outer
        >
          <slot :name="key + '-append-outer'" />
        </template>
      </v-text-field>
    </span>
    <v-dialog
      v-model="helpDialog"
      max-width="590"
    >
      <v-card>
        <v-card-title>
          {{ helpTitle }}
        </v-card-title>
        <v-card-text>
          {{ helpContent }}
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
    import {VAutocomplete, VCombobox} from "vuetify/lib";
    import rules from "@/utils/formRules";
    import {has} from "@/utils/object";
    import {HelpProvider} from "@/service/HelpProvider";
    import {ACLMixin} from "@/app/mixins/ACLMixin";
    import FormDateTimePicker from "@/app/components/form/FormDateTimePicker.component";
    import FormIntervalPicker from "./FormIntervalPicker";
    import {TimeIntervals} from "@/enum/time_intervals";

    export default {
        name: "FormFields",
        components: {FormIntervalPicker, FormDateTimePicker, VAutocomplete, VCombobox}, // dynamic components have to be manually imported
        mixins: [ACLMixin],
        props: {
            form: {
                type: Object,
                default: () => ({})
            },
            render: {
                type: Object, // TODO document this somewhere
                default: () => ({})
            },
            langPath: {
                type: String,
                default: 'base.'
            },
            stretchForm: {
                type: Boolean,
                default: false
            },
        },
        data: () => ({
            pwVisible: {},
            helpDialog: false,
            helpKey: null,
            TimeIntervals: TimeIntervals
        }),
        computed: {
            helpTitle: function () {
                const help = HelpProvider.get();
                return has(help, this.helpKey) ? help[this.helpKey].title : '';
            },
            helpContent: function () {
                const help = HelpProvider.get();
                return has(help, this.helpKey) ? help[this.helpKey].content : '';
            },
        },
        createdOrActivated: function () {
            this.fetchAutocompletes();
        },
        methods: {
            getFieldRules: function (field) {
                return (field.rules === undefined ? [] : field.rules)
                    .concat(field.max === undefined ? [] : [rules.maxLen(field.max)])
                    .concat(field.required !== true ? [] : [rules.required]);
            },
            fetchAutocompletes: function () { // fetch autocompletes
                for (const input in this.render) {
                    if (has(this.render, input)) {
                        const field = this.render[input];
                        if (has(field, 'autocomplete')) {
                            if (has(field.autocomplete, 'items')) {
                                if (has(field.autocomplete, 'autoFetched') && field.autocomplete.autoFetched) {
                                    // Items has been fetched previously, but we should refresh them in case something has changed
                                    this.actuallyFetchAutocompletes(field);
                                } else {
                                    // Nothing to do here, items are handled from the component
                                }
                            } else {
                                if (field.readonly === true && !field.forceFetch) {
                                    // Nothing to do here, item is disabled so we will not load possible items
                                } else {
                                    this.actuallyFetchAutocompletes(field);
                                }
                            }
                            // Regardless of how items are loaded, watch them and when there is exactly one item, set it as selected
                            this.$watch(() => field.autocomplete.items
                                            && field.autocomplete.items.length === 1
                                            && field.autocomplete.items[0].value,
                                        setFirst => {
                                            if (setFirst) {
                                                this.$set(this.form, input, field.autocomplete.multiple ? [setFirst] : setFirst);
                                            }
                                        }
                            );
                        }
                    }
                }
            },
            actuallyFetchAutocompletes: function (field) {
                this.$set(field, 'loading', true);
                field.autocomplete.callFn()
                    .then(response => {
                        const items = field.autocomplete.thenFn(response);
                        if (field.prepend) {
                            items.unshift(field.prepend);
                        }
                        this.$set(field.autocomplete, 'items', items);
                        this.$set(field.autocomplete, 'autoFetched', true);
                    }).catch(err => {
                        if (typeof field.autocomplete.catchFn === 'function') {
                            field.autocomplete.catchFn(err);
                        } else {
                            this.snack(err);
                        }
                    }).finally(() => {
                        this.$set(field, 'loading', false);
                    });
            },
            handleValue: function (key, fieldType) { // Handle values when field type is numeric.
                if (fieldType === 'number' && this.form[key] !== "") {
                    const value = this.form[key];
                    // eslint-disable-next-line vue/no-mutating-props
                    this.form[key] = this.$options.filters.priceToFixed(value,2);
                }
                this.clearUserErrors();
            },
            clearUserErrors: function () {
                for (const item in this.render) {
                    if (has(this.render, item)) {
                        // eslint-disable-next-line vue/no-mutating-props
                        delete this.render[item].errors;
                    }
                }
            },
            pwVisibleClick: function (key) {
                if (this.pwVisible[key] === undefined) {
                    this.$set(this.pwVisible, key, true);
                } else {
                    this.$set(this.pwVisible, key, !this.pwVisible[key]);
                }
            },
            helpClick: function (helpKey) {
                this.helpKey = helpKey;
                this.helpDialog = true;
            }
        }
    };

    // TODO multiple select all https://vuetifyjs.com/en/components/selects#prepend-append-item-slots
</script>

<style scoped lang="sass">
.hidden
  display: none !important

</style>
