import {TaskState} from "@/enum/task_state";
import {taskTypes, taskNames} from "@/enum/task_type";
import {TaskPriority} from "@/enum/task_priority";
import {UserAPI} from "@/api/UserAPI";
import {ACLMixin} from "@/app/mixins/ACLMixin";
import {setDeep} from "@/utils/object";
import {StockAPI} from "@/api/StockAPI";
import {defaultDashboard} from "@/app/homepage/definitions/defaultDashboard.definition";
import {APIFilterOP, APIFilters} from "@/service/APIFilters";
import {FilterValidator} from "@/service/FilterValidator";

const TaskFilterMixin = {
    mixins: [ACLMixin],
    computed: {
        renderValues: function () {
            const values = {};
            for (const property of Object.keys(this.possibleValues)) {
                if (Array.isArray(this.possibleValues[property].values)) {
                    for (const possibility of this.possibleValues[property].values) {
                        setDeep(values, property + '.' + possibility.value, possibility.text);
                    }
                }
            }
            return {
                tasks: {
                    filter: values
                }
            };
        }
    },
    methods: {
        getDefaultLists: function () {
            const defaultLists = defaultDashboard();
            for (const list of defaultLists) {
                list.showOnDesktop = true;
                list.showOnMobile = true;
                list.props.autoRefresh = false;
                list.props.autoRefreshIn = 60;
                list.props.label = this.$t('homepage.' + list.props.label);
                if (Array.isArray(list.props.filter)) {
                    list.props.filter = {[APIFilterOP.AND]: list.props.filter};
                }
            }
            return defaultLists;
        },
        fetchPossibleValues: async function () {
            const promises = [];
            promises.push(
                StockAPI.getAllPages().then(response => {
                    const subStocksPromise = StockAPI.getAllSubstocksAllPages().then(secondResponse => {
                        const subStocksByStock = secondResponse.data.items.reduce((acc, subStock) => {
                            const stockId = subStock.stock_id;
                            if (!acc[stockId]) {
                                acc[stockId] = [];
                            }
                            acc[stockId].push(subStock);
                            return acc;
                        }, {});

                        const possibleValues = [];
                        response.data.items.forEach(stock => {
                            stock.subStocks = subStocksByStock[stock.id] ?? [];
                            if (!stock.subStocks.length) {
                                return;
                            }
                            possibleValues.push({
                                header: stock.name
                            });
                            stock.subStocks.forEach(subStock => {
                                possibleValues.push({
                                    text: subStock.name,
                                    value: subStock.id
                                });
                            });
                        });
                        this.possibleValues['substock.id'].values = possibleValues;
                    });
                    promises.push(subStocksPromise);
                })
            );
            this.possibleValues.state.values = Object.values(TaskState).map(state => ({
                text: this.$t('tasks.state.' + state),
                value: state
            }));
            this.possibleValues.priority.values = Object.values(TaskPriority).map(priority => ({
                text: this.$t('tasks.priority.' + priority),
                value: priority
            }));
            this.possibleValues.type.values = Object.values(taskTypes).map(type => ({
                text: this.$t(taskNames[type]),
                value: type
            }));
            if (this.isChief) {
                promises.push(
                    UserAPI.getAll()
                        .then(response => {
                            const allUsers = response.data.map(user => ({
                                text: this.$options.filters.fullName(user),
                                value: user.id
                            }));
                            this.possibleValues['assigned_user.id'].values = allUsers;
                            this.possibleValues['created_by'].values = allUsers;
                            this.possibleValues['updated_by'].values = allUsers;
                        }).catch(this.snack)
                );
            } else { // storekeeper can only filter himself
                const user  = [{
                    text: this.$store.getters['oauth/getUserFullName'],
                    value: this.$store.getters['oauth/getUserId']
                }];
                this.possibleValues['assigned_user.id'].values = user;
                this.possibleValues['created_by'].values = user;
                this.possibleValues['updated_by'].values = user;

            }
            return Promise.all(promises);
        },
        validateFilter: function (filterObject) {
            return FilterValidator.validate(filterObject, APIFilters, APIFilterOP, this.possibleValues);
        }
    }
};

export {TaskFilterMixin};
