<template>
  <div>
    <v-card class="ma-1">
      <v-card-title>
        {{ $t(titlePath) }}
      </v-card-title>
      <v-card-text>
        <v-form class="d-flex flex-row align-top">
          <v-autocomplete
            v-model="processingMode"
            dense
            outlined
            :loading="loading"
            :readonly="loading"
            :items="possibleProcessingModes"
            prepend-icon="$taskExternalOrderProcessingMode"
            persistent-hint
            :hint="taskInfo.details.changeable_mode ?
              $t('tasks.externalOrder.processingMode.hint.' + processingMode) :
              $t('tasks.externalOrder.processingMode.disabledHint')"
            :disabled="!taskInfo.details.changeable_mode"
            @keyup.enter.native="confirmProcessingMode"
          >
            <template #item="{ item }">
              <v-layout>
                <v-flex>
                  {{ item.text }}
                </v-flex>
                <v-flex class="text-right">
                  <v-tooltip right>
                    <template #activator="{ on }">
                      <div v-on="on">
                        <v-icon>$info</v-icon>
                      </div>
                    </template>
                    <span>{{
                      $t('tasks.externalOrder.processingMode.hint.' + item.value)
                    }}</span>
                  </v-tooltip>
                </v-flex>
              </v-layout>
            </template>
          </v-autocomplete>
          <v-btn
            :outlined="initialProcessingMode === processingMode"
            :disabled="processingMode === null || initialProcessingMode === processingMode"
            :loading="loading"
            class="ml-2"
            type="submit"
            color="accent"
            @click.prevent="confirmProcessingMode"
          >
            {{ $t('base.save') }}
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
    import {TaskExternalOrderAPI} from "@/api/TaskExternalOrderAPI";
    import {TaskExternalOrderProcessingMode} from "@/enum/task_external_order_processing_mode";

    export default {
        name: "ExternalOrderProcessingModePicker",
        props: {
            taskInfo: {
                type: Object,
                default: () => ({})
            },
            changeRequired: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            initialProcessingMode: null,
            processingMode: null,
            loading: false
        }),
        computed: {
            titlePath: function () {
                let basePath = 'tasks.externalOrder.processingMode.';
                if (this.changeRequired) {
                    return basePath + 'changeRequired';
                }
                return basePath + 'label';
            },
            possibleProcessingModes: function () {
                return Object.values(TaskExternalOrderProcessingMode).map(val => ({
                    text: this.$t('tasks.externalOrder.processingMode.' + val),
                    value: val
                }));
            }
        },
        watch: {
            'taskInfo.details.processing_mode': {
                handler: function (newValue) {
                    this.initialProcessingMode = newValue;
                    this.processingMode = newValue;
                },
                immediate: true
            }
        },
        methods: {
            confirmProcessingMode: function () {
                this.loading = true;
                TaskExternalOrderAPI.updateProcessingMode(this.taskInfo.taskId, this.processingMode)
                    .then(() => {
                        this.$set(this.taskInfo.details, 'processing_mode', this.processingMode);
                        this.$emit('processing-mode-changed');
                    })
                    .catch(this.snack)
                    .finally(() => this.loading = false);
            }
        }
    };
</script>

<style scoped>

</style>