<template>
  <div>
    <x-toolbar />
    <v-card>
      <v-container fluid>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent
        >
          <FormFields
            ref="fields"
            :form="form"
            :render="formRender"
            lang-path="tasks.moveProducts."
          >
            <template #transfer_mode-item="{ item }">
              <TaskCreateStrictModeItem
                :item="item"
                lang-path="tasks.moveProducts.strictHint."
              />
            </template>
          </FormFields>
          <v-flex
            v-if="form.subordinate_stock_id"
            xs12
          >
            <TaskChooseItems
              :chosen-items.sync="chosenItems"
              :valid.sync="validItems"
              title-path="tasks.moveProducts.items.productsToMove"
              :readonly="loading"
              :only-in-substock-id="form.subordinate_stock_id"
              :only-on-location-id="form.source_location_id > 0 ? form.source_location_id : null"
              :allow-empty="form.transfer_mode === TaskItemsStrictMode.FREE"
            />
          </v-flex>
          <v-flex
            v-if="form.subordinate_stock_id"
            xs12
          >
            <v-divider
              class="my-3"
            />
            <TaskAttributes
              :task-type="type"
              :stock-id="form.stock_id"
              :sub-stock-id="form.subordinate_stock_id"
              :submit-trigger="attributesSubmitTrigger"
              inline
              @update:valid="val => validAttributes = val"
              @update-submit-callback="callback => attributesSubmitCallback = callback"
            />
          </v-flex>
          <v-flex
            xs12
          >
            <v-divider
              class="my-3"
            />
            <TaskAttachmentUpload
              v-model="validAttachments"
              :files-to-attach="form.attachments"
            />
          </v-flex>
          <v-flex
            xs12
          >
            <v-divider
              class="my-3"
            />
            <v-btn
              :text="!validAll"
              :loading="loading"
              type="submit"
              color="accent"
              @click="submitAfterEventCycle"
            >
              <v-icon
                class="mr-2"
              >
                $taskMoveProducts
              </v-icon>
              {{ $t('tasks.moveProducts.create') }}
            </v-btn>
          </v-flex>
        </v-form>
      </v-container>
    </v-card>
    <div class="my-2">
      <StockLocationCreateUpdate
        v-if="showCreateLocation"
        id="locationForm"
        ref="locationForm"
        :is-edit="false"
        is-component
        :parent-stock-id="form.stock_id"
      />
    </div>
  </div>
</template>

<script>
    import FormFields from "@/app/components/form/FormFields.component";
    import {
        MoveProductsCreateForm,
        MoveProductsCreateRender
    } from "@/app/tasks/moveProducts/definitions/moveProductCreate.form";
    import {formMountedProcedure, setFormErrors} from "@/utils/form";
    import {TaskMoveProductsAPI} from "@/api/TaskMoveProductsAPI";
    import {StockAPI} from "@/api/StockAPI";
    import formRules from "@/utils/formRules";
    import TaskAttachmentUpload from "@/app/tasks/components/TaskAttachmentUpload.component";
    import {locationLabel} from "@/utils/string";
    import StockLocationCreateUpdate from "@/app/stocks/locations/StockLocationCreateUpdate.view";
    import {scrollTo} from "@/service/Vuetify";
    import TaskChooseItems from "@/app/tasks/components/TaskChooseItems.component";
    import {taskLinks, taskTypes} from "@/enum/task_type";
    import {getIdFromLocation} from "@/utils/url";
    import TaskCreateStrictModeItem from "@/app/tasks/components/TaskCreateStrictModeItem.component";
    import {TaskItemsStrictMode} from "@/enum/task_items_strict_mode";
    import {EventsListenerMixin} from "@/app/mixins/EventsListenerMixin";
    import TaskAttributes from "@/app/tasks/components/taskAttributes/TaskAttributes.component.vue";

    export default {
        name: "MoveProductsCreate",
        components: {
            TaskAttributes,
            TaskCreateStrictModeItem,
            StockLocationCreateUpdate, TaskAttachmentUpload, FormFields, TaskChooseItems
        },
        mixins: [EventsListenerMixin],
        data: () => ({
            form: new MoveProductsCreateForm,
            formRender: new MoveProductsCreateRender,
            valid: true,
            validAttachments: true,
            validItems: true,
            validAttributes: true,
            loading: false,
            chosenItems: [{product_id: null, product_instance_id: null, quantity: 1}],
            formRules: formRules,
            showCreateLocation: false,
            TaskItemsStrictMode: TaskItemsStrictMode,
            type: taskTypes.MOVE_PRODUCTS,
            attributesSubmitTrigger: 0,
            attributesSubmitCallback: null,
        }),
        computed: {
            events: function () {
                return {
                    'create-location': this.onCreateLocation,
                    'create-location-created': this.onCreateLocationCreated,
                    'create-location-cancelled': () => this.showCreateLocation = false
                };
            },
            validAll: function () {
                return this.valid && this.validAttachments && this.validItems && this.validAttributes;
            }
        },
        watch: {
            'form.stock_id': function (newValue) {
                if (newValue !== undefined && newValue !== null) {
                    this.stockIdChanged();
                }
            },
            'form.subordinate_stock_id': function (newValue) {
                if (newValue !== undefined && newValue !== null) {
                    this.subStockIdChanged();
                }
            }
        },
        mounted: function () {
            formMountedProcedure(this);
        },
        methods: {
            onCreateLocation: function () {
                if (this.form.stock_id !== undefined && this.form.stock_id !== null
                    && this.form.subordinate_stock_id !== undefined && this.form.subordinate_stock_id !== null) {
                    this.showCreateLocation = true;
                    if (this.$refs.fields !== undefined) {
                        this.$refs.fields.$refs.destination_location_id[0].blur();
                    }
                    this.$nextTick(() => {
                        scrollTo('locationForm');
                    });
                }
            },
            onCreateLocationCreated: function (locationId) {
                this.showCreateLocation = false;
                StockAPI.getAllSubstockWhitelistedLocations(this.form.stock_id, this.form.subordinate_stock_id).then(response => {
                    const promises = [];
                    if (response.data.length !== 0) {
                        promises.push(
                            StockAPI.createSubstockWhitelistLocation(this.form.stock_id, this.form.subordinate_stock_id, locationId)
                        );
                    }
                    Promise.all(promises).then(() => {
                        this.loadLocations();
                        this.form.destination_location_id = Number.parseInt(locationId, 10);
                    });
                });
            },
            stockIdChanged: function () {
                this.form.subordinate_stock_id = null;
                this.loadSubStocks();
            },
            subStockIdChanged: function () {
                this.loadLocations();
            },
            loadSubStocks: function () {
                this.$set(this.formRender.subordinate_stock_id, 'loading', true);
                StockAPI.getAllSubstocksForStockAllPages(this.form.stock_id)
                    .then(response => {
                        this.form.subordinate_stock_id = null;
                        this.formRender.subordinate_stock_id.autocomplete.items = response.data.items.map(el => ({
                            text: el.name,
                            value: el.id
                        }));
                    }).finally(() => {
                        this.$set(this.formRender.subordinate_stock_id, 'loading', false);
                    });
            },
            loadLocations: function () {
                this.$set(this.formRender.source_location_id, 'loading', true);
                this.$set(this.formRender.destination_location_id, 'loading', true);
                // TODO use x-autocomplete in the form
                StockAPI.getAllSubstockAvailableLocationsAllPages(this.form.stock_id, this.form.subordinate_stock_id)
                    .then(response => {
                        const items = [{
                            text: this.$t('tasks.moveProducts.anyLocation'),
                            value: -1
                        }].concat(response.data.items.map(el => ({
                            text: locationLabel(el),
                            value: el.id
                        })));
                        this.formRender.source_location_id.autocomplete.items = items;
                        this.formRender.destination_location_id.autocomplete.items = items;
                    }).finally(() => {
                        this.$set(this.formRender.source_location_id, 'loading', false);
                        this.$set(this.formRender.destination_location_id, 'loading', false);
                    });
            },
            submitAfterEventCycle: function () {
                setTimeout(this.submit);
            },
            submit: function () {
                if (!this.validAll) {
                    this.$refs.form.validate();
                    return;
                }
                if (this.form.source_location_id === this.form.destination_location_id) {
                    if (this.form.source_location_id !== -1) {
                        this.snack('tasks.moveProducts.unableToMoveToSame');
                        return;
                    }
                }
                this.loading = true;
                this.attributesSubmitTrigger++;
                const data = {...this.form};
                if (data.source_location_id === -1) {
                    data.source_location_id = null;
                }
                if (data.destination_location_id === -1) {
                    data.destination_location_id = null;
                }
                data.items = this.chosenItems
                    .filter(item => {
                        delete item.errors;
                        return item.product_instance_id;
                    });
                TaskMoveProductsAPI.create(data)
                    .then(response => {
                        const taskId = getIdFromLocation(response);
                        this.attributesSubmitCallback(taskId)
                            .then(() => {
                                this.snack('tasks.moveProducts.createDone');
                                this.$route.meta.uuid = this.$route.meta.uuid + 1 || 1;
                                this.$router.push(taskLinks[this.type] + '/' + taskId);
                            }).catch(this.snack)
                            .finally(() => this.loading = false);
                    }).catch(err => {
                        // error is not handled, need to get errors manually
                        if (err.response.status === 409) {
                            const problems = err.response.data.blocking_items || [];
                            this.chosenItems.filter(item => problems.find(problem => problem.instance_id === item.product_instance_id)).forEach(item => {
                                this.$set(item, 'errors', [this.$t('tasks.moveProducts.notEnoughItems')]);
                            });
                        } else {
                            setFormErrors.call(this, err.response.data.errors);
                        }
                        this.loading = false;
                    });
            }
        }
    };
</script>

<style scoped>

</style>
